import React, { useState, useRef, useEffect } from "react";
import swal from "sweetalert";
import InputMask from "react-input-mask";
import { CardItem } from "../cardItem/cardItem";
import "./redpay.scss";
import { cardService } from "../../../services/card.service";
import { countryService } from "../../../services/country.service";
import { setBlocking } from "../../../hooks/blocking.hook";
import { useSelector } from 'react-redux';

export const RedPay = (props) => {
    const countrySetting = useSelector(state => state.countrySetting);
    const [card, setCard] = useState({
        CardId: "",
        FirstName: "",
        LastName: "",
        CardNumber: "",
        CardType: "",
        CardExpirationMonth: "",
        CardExpirationYear: "",
        Cvv: "",
        TypeCardId: "",
        CountryId: "",
        IdState: "",
        City: "",
        PostalCode: "",
        Street: "",
        StreetNumber: ""
    });

    const [cardValidators, setCardValidators] = useState({
        CardNumber: "",
        FirstName: "",
        LastName: "",
        CardExpirationMonth: "",
        CardExpirationYear: "",
        Cvv: "",
        City: "",
        PostalCode: "",
        Street: "",
        StreetNumber: ""
    });

    const [lstCountry, setLstCountry] = useState([]);
    const [lstStateCountry, setLstStateCountry] = useState([]);
    const [country, setCountry] = useState(0);
    const [stateCountry, setStateCountry] = useState(0);
    const [typeCard, setTypeCard] = useState(0);
    const submitRef = useRef();
    const selectCard = (item) => {
        setCountry(0);
        setStateCountry(0);
        setTypeCard(0);

        item.City = item.City != null || item.City != undefined ? item.City : "";
        item.PostalCode = item.PostalCode != null || item.PostalCode != undefined ? item.PostalCode : "";
        item.Street = item.Street != null || item.Street != undefined ? item.Street : "";
        item.StreetNumber = item.StreetNumber != null || item.StreetNumber != undefined ? item.StreetNumber : "";

        if (item.CountryId != undefined || item.CountryId != null) {
            countryService.getStateByCountry(item.CountryId)
                .then((res) => {
                    setLstStateCountry(res);
                    setCard(item);
                    setCountry(item.CountryId);
                    setStateCountry(item.IdState);
                    setTypeCard(item.TypeCardId);
                });
        } else {
            setCard(item);
        }
    };

    const handleChange = async (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setCard({ ...card, [name]: value });
        validateItem(event.target);
    };

    const handleChangeCard = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const cardType = getCardType(value);
        setCard({ ...card, [name]: value, CardType: cardType });
        validateItem(e.target);
    };

    const getCardType = (value) => {
        let cardNumber = value.replace(/ /g, "").replace(/X/g, "");
        const card_american = /^3[47]/;
        const card_visa = /^4/;         
        const card_master =
            /^(5[1-5]|2[2-7])/;
        let cardType = "";
        if (cardNumber.match(card_visa)) cardType = "001";
        else if (cardNumber.match(card_master)) cardType = "002";
        else if (cardNumber.match(card_american)) cardType = "003";

        return cardType;
    };

    const validateItem = async (input) => {
        const name = input.name;
        const value = input.value;
        switch (name) {
            case "FirstName":
            case "LastName":
                cardValidators[name] =
                    card.CardId == "new" &&
                    value.length == 0 &&
                    "Éste campo es obligatorio";
                break;
            case "CardNumber":
                let expectedCardNumberLength = (card.CardType === "003") ? 15 : 16;
                cardValidators[name] = (card.CardId === "new" &&  value.length !== expectedCardNumberLength)
                    ? "* El número de tarjeta debe contener " + expectedCardNumberLength + " dígitos"
                    : "";
                break;
            case "Cvv":
                let expectedCvvLength = (card.CardType === "003") ? 4 : 3; 
                cardValidators[name] = (value.length !== expectedCvvLength)
                    ? "* El código de seguridad debe contener " + expectedCvvLength + " dígitos"
                    : "";
                break;
            case "CardExpirationYear":
                cardValidators[name] =
                    value.length != 4 && "* El año de expiración debe contener 4 dígitos";
                break;
            case "CardExpirationMonth":
                cardValidators[name] =
                    value.length != 2 && "* El mes de expiración debe contener 2 dígitos";
                break;
            case "City":
                cardValidators[name] = value.length == 0 && "* Ciudad requerida";
                break;
            case "PostalCode":
                cardValidators[name] = value.length == 0 && "* Ingrese un código postal valido";
                break;
            case "Street":
                cardValidators[name] = value.length == 0 && "* Ingrese el nombre de su calle";
                break;
            case "StreetNumber":
                cardValidators[name] = value.length == 0 && "* Ingrese el número exterior de su domicilio";
                break;
        }

        setCardValidators({
            ...cardValidators,
        });
    };

    const validateForm = () => {
        const inputs = document.forms["paymentForm"].getElementsByTagName("input");
        const validationInputs = Object.keys(cardValidators);

        for (let input of inputs) {
            if (validationInputs.includes(input.name)) validateItem(input);
        }

        let failedInputs;
        failedInputs = Object.values(cardValidators).find(
            (value) => value == null || value.length > 0
        );

        return failedInputs === undefined;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateForm() && !submitRef.current.disabled) {
            const validateBin = await validateBinNumber();
            if (!validateBin)
                return swal({
                    text: "El bin de esta tarjeta no es permitido",
                    icon: "error",
                });


            if (typeCard == null || typeCard == 0) {
                return swal({
                    text: "Debe seleccionar el tipo de tarjeta",
                    icon: "error",
                });
            }
            if (country == null || country == 0) {
                return swal({
                    text: "Debe seleccionar el país",
                    icon: "error",
                });
            }
            if (stateCountry == null || stateCountry == 0) {
                return swal({
                    text: "Debe seleccionar el estado",
                    icon: "error",
                });
            }

            setCard({ ...card, ["TypeCardId"]: typeCard });
            setCard({ ...card, ["CountryId"]: country });
            setCard({ ...card, ["IdState"]: stateCountry });

            submitRef.current.disabled = true;
            setBlocking(true);
            document.getElementById("paymentForm").submit();
        }
    };

    const cancel = () => {
        window.location.href = "javascript:history.go(-1)";
    };

    const validateBinNumber = async () => {
        if (card.CardId == "new")
            return cardService
                .validateBin(card.CardNumber.substring(0, 6))
                .then((res) => res.IsSuccessful);
        else return true;
    };

    const getAllCountry = () => {
        countryService.getAllCountry()
            .then((res) => {
                setLstCountry(res);
            });
    }

    const getCountryState = (countryId) => {
        if (countryId != undefined || countryId != null) {
            countryService.getStateByCountry(countryId)
                .then((res) => {
                    setCountry(countryId);
                    setLstStateCountry(res);
                });
        }
    }

    const changeCountryState = (countryStateId) => {
        setStateCountry(countryStateId);
    }

    const changeTypeCard = (TypeCard) => {
        setTypeCard(TypeCard);
    }

    useEffect(() => {
        getAllCountry();
    }, []);

    return (
        <form action={props.UrlSubmit} method="POST" id="paymentForm">
            <input
                type="text"
                className="hidden"
                value={props.OrderId}
                name="OrderId"
                readOnly
            ></input>
            <input
                type="text"
                className="hidden"
                value={card.CardId}
                name="CardId"
                readOnly
            ></input>
            <input
                type="text"
                className="hidden"
                value={card.CardType}
                name="CardType"
                readOnly
            ></input>
            <section className="wrapper-banorte container-fluid">
                {lstCountry.length > 0 ? <CardItem selectCard={selectCard} /> : ""}
                <div className="row">
                    <div className="col-sm-5">
                        <h1>Datos del comprador</h1>
                        <div className="data-side">
                            <div className="data-content">
                                <label className="mb-2">Comprador</label>
                                <div>
                                    <p className="pmail font-italic fw300">
                                        <i className="fas fa-envelope"></i> {props.Email}
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <label>Número de pedido</label>
                                        <p className="data-p mb-0">{props.OrderNumber}</p>
                                    </div>
                                    <div className="col-6">
                                        <label>Monto</label>
                                        <p className="amount-p mb-0">{countrySetting.currency}{props.Amount.toString().toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} {countrySetting.isoCurrency}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {card.CardId && (
                        <div className="col-sm-7">
                            <h1>Pago con tarjeta</h1>
                            <div className="payment-side">
                                <div className="payment-content">
                                    <h6>Datos de la tarjeta</h6>
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <p>Tipo de tarjeta</p>
                                            <select className={`payment-card`} onChange={e => changeTypeCard(e.target.value)} value={typeCard} name="TypeCardId">
                                                <option key="TypeTarget0" value={0}>Seleccionar</option>
                                                <option key="TypeTarget1" value={1}>Debito</option>
                                                <option key="TypeTarget2" value={2}>Credito</option>
                                            </select>
                                        </div>
                                    </div>
                                    <p>Número de tarjeta</p>
                                    {card.CardId != "new" ? (
                                        <h5>{card.CardNumber}</h5>
                                    ) : (
                                        <>
                                            <InputMask
                                                className={`payment-card ${cardValidators.CardNumber && "error"
                                                    }`}
                                                placeholder="XXXXXXXXXXXXXXXX"
                                                mask={card.CardType == "003" ? "999999999999999" : "9999999999999999"}
                                                size="20"
                                                name="CardNumber"
                                                value={card.CardNumber}
                                                onChange={handleChangeCard}
                                                maskChar=""
                                            />
                                            {cardValidators.CardNumber && (
                                                <small>{cardValidators.CardNumber}</small>
                                            )}
                                        </>
                                    )}
                                    <div className="row align-items-end">
                                        <div className="col-6 col-md-4">
                                            <p>Fecha de expiración</p>
                                            <InputMask
                                                className={`payment-date ${cardValidators.CardExpirationMonth && "error"
                                                    }`}
                                                placeholder="MM"
                                                mask="99"
                                                name="CardExpirationMonth"
                                                maskChar=""
                                                onChange={handleChange}
                                            />
                                            {cardValidators.CardExpirationMonth && (
                                                <small>{cardValidators.CardExpirationMonth}</small>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <InputMask
                                                className={`payment-date ${cardValidators.CardExpirationYear && "error"
                                                    }`}
                                                placeholder="AAAA"
                                                mask="9999"
                                                name="CardExpirationYear"
                                                maskChar=""
                                                onChange={handleChange}
                                            />

                                            {cardValidators.CardExpirationYear && (
                                                <small>{cardValidators.CardExpirationYear}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <p>Código de seguridad</p>
                                            <InputMask
                                                className={`payment-code ${cardValidators.Cvv && "error"}`}
                                                mask={card.CardType == "003" ? "9999" : "999"}
                                                name="Cvv"
                                                maskChar=""
                                                onChange={handleChange}
                                                type="password"
                                            />
                                            {cardValidators.Cvv && (<small>{cardValidators.Cvv}</small>)}
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <span>
                                                <i className="fa fa-credit-card card-i"></i>
                                            </span>
                                            <span className="card-text">
                                                {card.CardType === "003" ? "4" : "3"} dígitos al reverso de la tarjeta
                                            </span>
                                        </div>
                                    </div>
                                    <h6 className="mt-2">Datos del propietario de la tarjeta</h6>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p>Nombre</p>
                                            {card.CardId != "new" ? (
                                                <h5>{card.FirstName}</h5>
                                            ) : (
                                                <>
                                                    <input
                                                        type="text"
                                                        className={`payment-card ${cardValidators.FirstName && "error"
                                                            }`}
                                                        name="FirstName"
                                                        value={card.FirstName}
                                                        onChange={handleChange}
                                                    />
                                                    {cardValidators.FirstName && (
                                                        <small>{cardValidators.FirstName}</small>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <p>Apellido</p>
                                            {card.CardId != "new" ? (
                                                <h5>{card.LastName}</h5>
                                            ) : (
                                                <>
                                                    <input
                                                        type="text"
                                                        className={`payment-card ${cardValidators.LastName && "error"
                                                            }`}
                                                        name="LastName"
                                                        value={card.LastName}
                                                        onChange={handleChange}
                                                    />
                                                    {cardValidators.LastName && (
                                                        <small>{cardValidators.LastName}</small>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p>Pais</p>
                                            <select className={`payment-card`} onChange={e => getCountryState(e.target.value)} value={country} name="CountryId">
                                                <option value={0} defaultValue>Seleccionar</option>
                                                {lstCountry.length &&
                                                    (lstCountry.map((item) => (
                                                        <option value={item.CountryId}>{item.CountryName}</option>
                                                    )))}
                                            </select>
                                        </div>
                                        <div className="col-6">
                                            <p>Estado</p>
                                            <select className={`payment-card`} onChange={e => changeCountryState(e.target.value)} value={stateCountry} name="IdState">
                                                <option value={0} defaultValue>Seleccionar</option>
                                                {lstStateCountry &&
                                                    (lstStateCountry.map((item) => (
                                                        <option value={item.IdState}>{item.Name}</option>
                                                    )))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p>Codigo Postal</p>
                                            <>
                                                <input
                                                    type="number"
                                                    className={`payment-card ${cardValidators.PostalCode && "error"
                                                        }`}
                                                    name="PostalCode"
                                                    maxLength={5}
                                                    value={card.PostalCode}
                                                    onChange={handleChange}
                                                />
                                                {cardValidators.PostalCode && (
                                                    <small>{cardValidators.PostalCode}</small>
                                                )}
                                            </>
                                        </div>
                                        <div className="col-6">
                                            <p>Ciudad</p>
                                            <>
                                                <input
                                                    type="text"
                                                    className={`payment-card ${cardValidators.City && "error"}`}
                                                    name="City"
                                                    value={card.City}
                                                    onChange={handleChange}
                                                />
                                                {cardValidators.City && (
                                                    <small>{cardValidators.City}</small>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p>Calle</p>
                                            <>
                                                <input
                                                    type="text"
                                                    maxLength={40}
                                                    className={`payment-card ${cardValidators.Street && "error"
                                                        }`}
                                                    name="Street"
                                                    value={card.Street}
                                                    onChange={handleChange}
                                                />
                                                {cardValidators.Street && (
                                                    <small>{cardValidators.Street}</small>
                                                )}
                                            </>
                                        </div>
                                        <div className="col-6">
                                            <p>Número Exterior</p>
                                            <>
                                                <input
                                                    type="text"
                                                    maxLength={6}
                                                    className={`payment-card ${cardValidators.StreetNumber && "error"
                                                        }`}
                                                    name="StreetNumber"
                                                    value={card.StreetNumber}
                                                    onChange={handleChange}
                                                />
                                                {cardValidators.StreetNumber && (
                                                    <small>{cardValidators.StreetNumber}</small>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                    <button onClick={handleSubmit} ref={submitRef}>
                                        Pagar <strong>{countrySetting.currency}{props.Amount} {countrySetting.isoCurrency}</strong>
                                    </button>
                                    <div className="cancel-button-content">
                                        <button type="button" onClick={cancel}>
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                                <div className="payment-img-content">
                                    <img
                                        src="./imgs/paymentMethod/multicard.png"
                                        alt="VisaMastercard"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="information-side col-sm-12">
                        <div className="information-content">
                            <label className="information-title">
                                Su información está protegida
                            </label>
                            <p className="information-text">
                                Empleamos los más altos estándares de seguridad (SSL, 3D Secure)
                                para proteger su información personal y la de su tarjeta.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    );
};
