import React, { useState, useEffect } from "react";
import ItemProduct from "../itemProduct";
import AsideProvider from "../asideProvider";
import "./containProvider.scss";
import $ from "jquery";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { ModalMessage } from "../modalMessage";
import { useSelector } from "react-redux";

const ContainProvider = (props) => {
  const countrySetting = useSelector((state) => state.countrySetting);
  const outstandingProducts = props.OutstandingProducts;
  const products = props.CompanyProducts;

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [availableBranches, setAvailableBranches] = useState([]);

  const style = {
    show: { display: "flex" },
    hide: { display: "none" },
  };

  const [showModal, setShowModal] = useState(false);

  const addPruchaseItem = (itemData) => {
    let cartItems = JSON.parse(localStorage["pruchaseList"]);
    let totalItems = parseFloat(localStorage["pruchaseTotal"]);

    let item = {
      ProductId: itemData.ProductId,
      Name: itemData.Name,
      Price: itemData.ProductPrice,
      Quantity: 1,
      Code: itemData.ProductCode,
      UrlImage: props.UrlLogoClrs,
      Descripcion: itemData.Description,
      ProviderId: itemData.proveedorId,
      CountryCode: itemData.CountryCode,
      CampaingCode: itemData.CampaingCode,
      FcPromocionID: itemData.FcPromocionID,
      Total: itemData.ProductPrice,
      SettingProviderId: props.SettingProviderId,
      SettingProviderName: props.SettingProviderName,
      Reference: itemData.Reference,
      SaveReference: itemData.SaveReference,
    };

    let foundProvider = cartItems.find((p) => p.ProviderId == props.ProviderId);
    if (foundProvider != undefined) {
      let isNew = true;
      if (item.Reference == undefined) {
        let foundProduct = foundProvider.Items.find(
          (p) => p.ProductId == item.ProductId
        );
        if (foundProduct != undefined) {
          foundProduct.Quantity += item.Quantity;
          foundProduct.Total = foundProduct.Quantity * item.Price;
          isNew = false;
        }
      } else {
        let foundProduct = foundProvider.Items.find(
          (p) => p.ProductId == item.ProductId && p.Reference == item.Reference
        );
        if (foundProduct != undefined) {
          swal({
            text: "Esta referencia ya esta en carrito de compras",
            icon: "error",
          });
          return false;
        }
      }

      if (isNew) foundProvider.Items.push(item);

      foundProvider.Total += item.Total;
    } else {
      let itemProvider = {
        ProviderId: props.ProviderId,
        ProviderName: props.Name,
        Logo: props.UrlLogoClrs,
        Total: item.Price,
        Items: [item],
      };

      cartItems.push(itemProvider);
      $("#spanActivePurchase").click();
    }

    localStorage["pruchaseTotal"] = totalItems + item.Price;
    localStorage["pruchaseList"] = JSON.stringify(cartItems);
    setShowModal(true);
  };

  const getAvailableBranches = () => {
    fetch("/ClientApp/AvailableBranches.txt")
      .then((response) => response.json())
      .then((data) => {
        let states = [];
        data.map((item) => {
          let foundState = states.find((c) => c.State == item.Estado);
          if (foundState != undefined) {
            foundState.Branches.push(item.Cine);
          } else {
            let obj = {
              State: item.Estado,
              Branches: [item.Cine],
            };
            states.push(obj);
          }
        });
        setAvailableBranches(states);
      });
  };

  useEffect(() => {
    getAvailableBranches();
  }, []);

  return (
    <div className="contain_providers">
      <div className="contain_info_provaider">
        <div className="box_info_provaider">
          <h2 className="ttls_provider">{props.Name}</h2>
          <p className="info_provider">{props.Info}</p>
        </div>
        <div className="box_logo_provaider">
          <img src={props.UrlLogo} alt="" />
        </div>
      </div>

      <div className="wrapper_products_provider">
        <div className="wrapper_categ_mobile">
          <AsideProvider
            urlImage={props.urlImage}
            urlVideo={props.UrlVideo}
            toggleCategories={props.toggleCategories}
            isAll={props.isAll}
          />
        </div>
        <div className="main_ttls_prov mb-4">
          <div className="row align-items-center">
            <div className="col-sm-5">
              <h5 className="ttls fw600 mb-1">
                {props.isAll ? "Todos los productos" : "Lo más vendido"}
              </h5>
            </div>
            {/*<div className="col-sm-7 btns">*/}
            {/*    {props.ProviderId == 2 && countrySetting.isoCurrency == "MXN" ?*/}
            {/*        <button className="btn btn-primary" onClick={handleShow}>*/}
            {/*            Ver disponibilidad de cines*/}
            {/*        </button>*/}
            {/*        : ""*/}
            {/*    }*/}
            {/*</div>*/}
          </div>
        </div>
        <div
          className="content_products_provider row"
          style={!props.isAll ? style.show : style.hide}
        >
          {outstandingProducts != undefined
            ? outstandingProducts.map((item) => {
                return (
                  <ItemProduct
                    {...item}
                    settingProviderId={props.SettingProviderId}
                    settingProviderName={props.SettingProviderName}
                    providerName={props.Name}
                    urlRoot={props.StorageRoot}
                    addPruchaseItem={addPruchaseItem}
                    urlLogoClrs={props.UrlLogoClrs}
                  />
                );
              })
            : ""}
        </div>
        <div
          className="content_products_provider row"
          style={props.isAll ? style.show : style.hide}
        >
          {products != undefined
            ? products.map((item) => {
                return (
                  <ItemProduct
                    {...item}
                    settingProviderId={props.SettingProviderId}
                    settingProviderName={props.SettingProviderName}
                    providerName={props.Name}
                    urlRoot={props.StorageRoot}
                    addPruchaseItem={addPruchaseItem}
                    urlLogoClrs={props.UrlLogoClrs}
                  />
                );
              })
            : ""}
        </div>
      </div>
      <ModalMessage
        show={showModal}
        handleShow={setShowModal}
        message="Producto agregado exitosamente"
        title="Agregado"
        colorClass="ico_success"
        iconClass="far fa-check-circle"
      />
      {/*<Modal show={show && props.ProviderId == 2 && countrySetting.isoCurrency == "MXN"} onHide={handleClose} animation={false}>*/}
      {/*    <div className="row modal_availability">*/}
      {/*        <div className="col-12">*/}
      {/*            <span className="btn_closemodal" onClick={handleClose}>*/}
      {/*                <i className="fas fa-times"></i>*/}
      {/*            </span>*/}
      {/*            <div className="wpr_accordion">*/}
      {/*                <div className="wrp_ttls">*/}
      {/*                    <h1>Salas Cinépolis Operando</h1>*/}
      {/*                    <p className="paragraph">Para tu tranquilidad todas las salas están sujetas a prótocolos estrictos de seguridad, limpieza y desinfección.</p>*/}
      {/*                </div>*/}
      {/*                <Accordion >*/}
      {/*                    {*/}
      {/*                        availableBranches.map(item => {*/}
      {/*                            return <Card>*/}
      {/*                                <Accordion.Toggle as={Card.Header} eventKey={item.State}>*/}
      {/*                                    {item.State}*/}
      {/*                                </Accordion.Toggle>*/}
      {/*                                <Accordion.Collapse eventKey={item.State}>*/}
      {/*                                    <Card.Body>*/}
      {/*                                        <ul>*/}
      {/*                                            {*/}
      {/*                                                item.Branches.map(branch => {*/}
      {/*                                                    return <li>{branch}</li>*/}
      {/*                                                })*/}
      {/*                                            }*/}
      {/*                                        </ul>*/}
      {/*                                    </Card.Body>*/}
      {/*                                </Accordion.Collapse>*/}
      {/*                            </Card>*/}
      {/*                        })*/}

      {/*                    }*/}
      {/*                </Accordion>*/}
      {/*            </div>*/}
      {/*        </div>*/}

      {/*    </div>*/}
      {/*</Modal>*/}
    </div>
  );
};

export default ContainProvider;
