import React, { Component } from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import Layout from "./views/Layout";
import Home from "./views/Home";
import { Provider } from "./views/Provider";
import { ShoppingHistory } from "./views/ShoppingHistory";
import NoticePrivacy from "./views/NoticePrivacy";
import TermsAndConditions from "./views/TermsAndConditions";
import ResetPassword from "./views/ResetPassword";
import ForgotPassword from "./views/ForgotPassword";
import ConfirmedAccount from "./views/ConfirmedAccount";
import PaymentCanceled from "./views/PaymentCanceled";
import { PaymentMethod } from "./views/PaymentMethod";
import { Unsubscribe } from "./views/Unsubscribe";
import PurchaseOrderDetail from "./views/PurchaseOrderDetail";
import { CreateInvoice } from "./views/CreateInvoice";
import { RechargeView } from "./views/RechargeView";
import "./theme/main.scss";

export default class App extends Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route path="/provider" component={Provider} />
          <Route path="/shopping-history" component={ShoppingHistory} />
          <Route path="/notice-privacy" component={NoticePrivacy} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/confirmed-account" component={ConfirmedAccount} />
          <Route path="/payment-canceled" component={PaymentCanceled} />
          <Route path="/create-invoice/:id" component={CreateInvoice} />
          <Route
            path="/purchase-order-detail/:id"
            component={PurchaseOrderDetail}
          />
          <Route path="/unsubscribe/:status" component={Unsubscribe} />
          <Route path="/recharge/:id" component={RechargeView} />
          <Route path="/checkout" component={PaymentMethod} />
          <Route exact path="/:company?" component={Home} />
        </Switch>
      </Layout>
    );
  }
}
