import { callApi } from './service'

export const billingService = {
    getInformation,
    create,
    cancel
};

async function getInformation() {
    return await callApi("/billing/getinformation", "GET")
        .then(result => { return result.InvoiceInfoConfigs })
        .catch(error => {
            console.log('error', error);
            return [];
        });
}

async function create(body) {
    return await callApi("/billing/create", "Post", body)
        .then(result => result)
        .catch(error => {
            console.log('error', error);
            return {
                Message: error.message
            };
        });
}

async function cancel(body) {
    return await callApi("/billing/cancel", "Put", body)
        .then(result => result)
        .catch(error => {
            console.log('error', error);
            return {
                Message: error.message
            };
        });
}