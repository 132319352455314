import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "reactstrap";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import Login from "../login";
import Register from "../register";
import TotalPlay from "../register/totalplay";
import AsidePurchase from "../asidePurchase";
import ItemOptionsMenu from "../itemOptionsMenu";
import swal from "sweetalert";
import "./header.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setModal, setCompany } from "../../store/actions";

const Header = (props) => {
  const [showSurvey, setShowSurvey] = useState(false);
  const toggleSurvey = () => setShowSurvey(!showSurvey);
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const company = useSelector((state) => state.company);
  const companyLogo = useSelector((state) => state.companyLogo);
  const countrySetting = useSelector((state) => state.countrySetting);
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.modal);
  const toggle = () => dispatch(setModal());

  const [activeAside, setStateAside] = useState("");
  function AsidePurchaseBox() {
    if (activePurchase != "") {
      setStateAside(activeAside === "" ? " is-show" : "");
    } else {
      setStatusPurchase(true);
    }
  }

  const [activePurchase, setStatePurchase] = useState("");
  const setStatusPurchase = (status) => {
    setStatePurchase(status ? " active_purchase" : "");
  };

  const [btnMenu, setBtnMenu] = useState("fa-bars");
  const [menuMobile, setMenuMobile] = useState("");
  function MenuMobile() {
    setMenuMobile(menuMobile === "" ? " is-visible" : "");
    setBtnMenu(btnMenu === "fa-bars" ? " fa-times" : "fa-bars");
  }

  const [showLogeo, setShowLogeo] = useState(false);
  const toggleLogeo = () => {
    setShowLogeo(!showLogeo);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);

  const ButtonModal = () => {
    return company == "totalplay" && enrollment == "" ? (
      <div></div>
    ) : showLogeo === false ? (
      <div>
        <h6 className="fw400 ttls">¿Eres nuevo en Red Voucher Online?</h6>
        <p>
          <button className="btn btn_border_clrs" onClick={toggleLogeo}>
            Regístrate aquí
          </button>
        </p>
      </div>
    ) : (
      <div>
        <h6 className="fw400 ttls">¿Ya tienes una cuenta?</h6>
        <p>
          <button className="btn btn_border_clrs" onClick={toggleLogeo}>
            Entra aquí
          </button>
        </p>
      </div>
    );
  };

  const style = {
    content_info: {
      background: "url(./imgs/bglogin.jpg) no-repeat top center",
    },
  };

  const [enrollment, setEnrollment] = useState("");

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    setStatusPurchase(localStorage["pruchaseTotal"] != 0);
  });

  const logOff = () => {
    fetch("/api/Account/LogOff", { method: "POST" })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
      })
      .then(function (result) {
        if (result.Status) {
          dispatch(getUser());
          toggleDrop();
          history.push("/");
        } else {
          console.log(result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <header className="wrapper_header">
      <div className="box_head_top fw300">
        <div className="container_provider">
          <div className="content_one">
            <span>
              <i className="fas fa-envelope"></i>
              <a href={"mailto: " + props.MailContact}>{props.MailContact}</a>
            </span>
            {/*<span>*/}
            {/*  <i className="fas fa-phone"></i>*/}
            {/*  <a href={"tel:" + props.Telefono1}>{props.Telefono2}</a> /{" "}*/}
            {/*  <a href={"tel:+" + props.TelefonoOficina}>*/}
            {/*    {props.TelefonoOficina}*/}
            {/*  </a>*/}
            {/*</span>*/}
          </div>
          {/*<div className="content_two">*/}
          {/*  <span>Síguenos en:</span>*/}
          {/*  <span>*/}
          {/*    <a target="_blank" href="https://www.facebook.com/redvoucher">*/}
          {/*      <i className="fab fa-facebook-square"></i>*/}
          {/*    </a>*/}
          {/*  </span>*/}
          {/*  <span>*/}
          {/*    <a*/}
          {/*      target="_blank"*/}
          {/*      href="https://www.youtube.com/channel/UCvX18UUFBbrGrokwqrGBlKw"*/}
          {/*    >*/}
          {/*      <i className="fab fa-youtube"></i>*/}
          {/*    </a>*/}
          {/*  </span>*/}
          {/*  <span>*/}
          {/*    <a target="_blank" href="https://twitter.com/redvoucher">*/}
          {/*      <i className="fab fa-twitter-square"></i>*/}
          {/*    </a>*/}
          {/*  </span>*/}
          {/*</div>*/}
        </div>
      </div>

      <div className="box_head_content">
        <div className="container_provider box_flex align_center justify_between">
          <div className="box_brand_logo">
            <Link to="/">
                <img src="./imgs/mg_horizontal.png" alt="RedVoucher" />
            </Link>
          </div>
          <div className={`box_content_options trans ${menuMobile}`}>
            <div className="content_opt_a trans"></div>
            {companyLogo != "" && history.location.pathname == "/provider" ? (
              <div className="box_brand_logo_company">
                <img src={companyLogo} alt="Company" />
              </div>
            ) : (
              ""
            )}
            <div className="content_opt_b trans">
              <Link className="link_options dsktp" to="/">
                <span>
                  <i className="fas fa-th-large"></i>
                </span>
                <p className="text_link">Home</p>
              </Link>

              {history.location.pathname != "/checkout" && (
                <span
                  className={`link_options${activePurchase} dsktp`}
                  onClick={AsidePurchaseBox}
                  id="spanActivePurchase"
                >
                  <span className="ico_canasta ">
                    <i className="fas fa-shopping-cart"></i>
                  </span>
                  <p className="text_link">Mi carrito</p>
                </span>
              )}
              {user.isAuthenticated ? (
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDrop}
                  className="logeado link_options"
                >
                  <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                    className="box_user_logeado"
                  >
                    <span className="conten_nameuser">
                      <p className="hello_user">¡Hola!</p>
                      <h6 className="name_user">{user.userName}</h6>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link
                      to="/shopping-history"
                      className="dropdown-item"
                      onClick={(toggleDrop, MenuMobile)}
                    >
                      <i className="fas fa-list-alt"></i> Mis compras
                    </Link>

                    <button className="dropdown-item" onClick={logOff}>
                      <i className="fas fa-power-off"></i> Cerrar sesión
                    </button>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <span className="link_options box_user" onClick={toggle}>
                  <div
                    className="text-center content_user"
                    onClick={MenuMobile}
                  >
                    <p className="ttl_user">MI CUENTA</p>
                    <p className="go">Entrar</p>
                  </div>
                </span>
              )}
            </div>
          </div>

          <div className="box_options_mobile">
            {companyLogo != "" && history.location.pathname == "/provider" ? (
              <div className="box_brand_logo_company">
                <img src={companyLogo} alt="Company" />
              </div>
            ) : (
              ""
            )}
            <Link className="link_options" to="/">
              <span>
                <i className="fas fa-th-large"></i>
              </span>
              <p className="text_link">Home</p>
            </Link>
            <span
              className={`link_options${activePurchase}`}
              onClick={AsidePurchaseBox}
              id="spanActivePurchase"
            >
              <span className="ico_canasta ">
                <i className="fas fa-shopping-cart"></i>
              </span>
              <p className="text_link">Mi carrito </p>
            </span>
            <span className="btn_mobile" onClick={MenuMobile}>
              <i className={`fas ${btnMenu}`}></i>
            </span>
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} className="wrapper_logeo">
        <div className="box_content_logeo">
          <div
            className={`content_info ${showLogeo === false ? " login" : ""}`}
            style={style.content_info}
          >
            <div>
              <h2 className="ttls font-italic fw700">Bienvenido</h2>
              <p className="p_info_wlcm font-italic fw300 mb-5">
                Bienvenido a Red Voucher Online, la mejor plataforma de
                descuentos y beneficios que te permite comprar lo que más te
                divierte.
              </p>
              <hr />
            </div>
            <ButtonModal />
          </div>

          {company == "totalplay" && enrollment == "" ? (
            <TotalPlay
              company={company}
              setEnrollment={setEnrollment}
              setShowLogeo={setShowLogeo}
            />
          ) : showLogeo === false ? (
            <Login toggleSurvey={toggleSurvey} />
          ) : (
            <Register enrollment={enrollment} />
          )}
          <span
            className="btn_close_modal trans"
            onClick={() => dispatch(setModal())}
          >
            <i className="fas fa-times-circle"></i> Cerrar
          </span>
        </div>
      </Modal>
      <AsidePurchase
        activeAside={activeAside}
        onCloseClick={() => setStateAside("")}
      />
      {/*<Modal
                className="modalisimo"
                isOpen={showSurvey}
                toggle={toggleSurvey}
            >
                <div className="row modal_alert">
                    <div className="modalcontent">
                        <span className="btn_closemodal" onClick={toggleSurvey}>
                            <i className="fas fa-times"></i>
                        </span>
                        <div className="">
                            <h4>Estimado Usuario: </h4>
                            <p className="paragraph">Tu opinión nos importa, por favor regálanos unos minutos para contestar la siguiente encuesta.</p>
                            <p className="text-center mt-4">
                                <a className='btn' href="https://www.survio.com/survey/d/U4E6M2P2B3A8L3R1X" target="_blank">Contestar encuesta</a>
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>*/}
      <Modal
        isOpen={showSurvey && countrySetting.isoCurrency == "MXN"}
        toggle={toggleSurvey}
        className="modalisimo"
      >
        <div className="row modal_alert">
          <div className="modalcontent text-center w-100">
            <span className="btn_closemodal" onClick={toggleSurvey}>
              <i className="fas fa-times"></i>
            </span>
            <div className="">
              {/*<h4 className="mb-1">¡Este San Valentín es digital!</h4>*/}
              <p className="paragraph">
                Encuentra en nuestro catálogo el regalo ideal, cuidando de la
                seguridad de seres queridos y garantizando su diversión.
              </p>
              <p className="mt-4">
                <a
                  className="btn"
                  target="_blank"
                  // download="Red Companies - Catálogo de productos"
                  href="https://somosredcompanies.com/assets/RedCo-Catalogo.pdf"
                  // onClick={toggleSurvey}
                >
                  Descarga catálogo
                </a>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </header>
  );
};

export default Header;
