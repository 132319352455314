import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { getUser, setModal } from '../../store/actions';

const Login = ({ toggleSurvey }) => {
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        fetch('/api/Account/Login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            if (response.ok) {
                return response.json()
            }
        }).then(function (result) {
            if (result.Status) {
                toggleSurvey();
                e.target.reset();
                dispatch(getUser());
                dispatch(setModal());
                //if (result.AlternativeEmail) alternativeEmail();
            } else {
                if (result.Message == "Confirm") swal({ title: "Ayúdanos a saber que eres tú", text: "Revisa tu correo electrónico y verifica tu cuenta", icon: "warning" });
                else swal({ text: result.Message, icon: "error" });

            }
        }).catch(function (error) {
            console.log(error);
            swal({ text: "Error", icon: "error" });
        });
    }

    const alternativeEmail = () => {
        swal({
            title: "No tienes un email alternativo registrado. ¿Deseas agregarlo?",
            icon: "warning",
            buttons: {
                yes: {
                    text: "Si",
                    value: "yes"
                },
                no: {
                    text: "No volver a preguntar",
                    value: "no"
                }
            }
        }).then((value) => {
            switch (value) {
                case "yes":
                    swalAlternativeEmail();
                    break;
                case "no":
                    updateAlternativeEmail("");
                    break;
            }
        })
    }

    const swalAlternativeEmail = () => {
        swal("Correo alternativo:", {
            content: "input",
        }).then(email => {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(email)) {
                updateAlternativeEmail(email);
            } else {
                swal({ text: "Email no valido", icon: "error" }).then(() => {
                    swalAlternativeEmail();
                })
            }
        })
    }

    const updateAlternativeEmail = (alternativeEmail) => {


        fetch('/api/Account/UpdateAlternativeEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ alternativeEmail: alternativeEmail })
        }).then(function (response) {
            if (response.ok) {
                return response.json()
            }
        }).then(function (result) {
            if (result.Status) {
                if (result.AlternativeEmail) {
                    swal("¡Correo agregado correctamente!", "A partir de ahora, recibirás una copia de todas las notificaciones de Red Voucher Online a este correo. ", "success");
                }
            } else {
                swal({ text: result.Message, icon: "error" }).then(() => {
                    swalAlternativeEmail();
                });
            }
        }).catch(function (error) {
            console.log(error);
            swal({ text: "Error al actualizar correo alternativo", icon: "error" })
        });
    }

    return (
        <div className="content_logeo login">

            <div className=" htop">
                <h5 className="ttls text-center mb-4">Iniciar sesión</h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label for="email-user">Correo electrónico</label>
                        <input type="email" className="form-control" name="Email" id="email-user" required ref={register} />
                    </div>
                    <div className="form-group mb-1">
                        <label for="password_user">Password</label>
                        <input type="password" className="form-control" name="Password" id="password_user" required ref={register} />
                    </div>
                    <div className="text-right fw500">
                        <small>
                            <Link to="/forgot-password">
                                <span onClick={() => dispatch(setModal())}>
                                    Olvide mi contraseña
                                </span>
                            </Link>
                        </small>
                    </div>
                    <p className="text-center mt-4">
                        <button type="submit" className="btn">Entrar</button>
                    </p>
                </form>
            </div>
        </div>
    )
}

export default Login;