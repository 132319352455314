import { callApi } from "./service";

export const cardService = {
  getCardList,
  deleteCard,
  validateBin,
};

async function getCardList() {
  return callApi("/card/getlistbyuserId", "Get")
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

async function deleteCard(cardId) {
  return callApi(`/card/deletecard?id=${cardId}`, "Delete");
}

async function validateBin(bin) {
  return callApi(`/card/ValidateBin?bin=${bin}`, "Get");
}
