import React, { Component } from 'react';
import $ from 'jquery';
import NoticePrivacyMexico from '../components/countryNoticePrivacy/mexico';
import NoticePrivacyCostaRica from '../components/countryNoticePrivacy/costaRica';
import NoticePrivacyGuatemala from '../components/countryNoticePrivacy/guatemala';
import { connect } from 'react-redux';

class NoticePrivacy extends Component {
    static displayName = NoticePrivacy.name;

    noticePrivacyByCountry = () => {
        switch (this.props.countrySetting.cultureCode) {
            case "es-MX":
                return <NoticePrivacyMexico />;
            case "es-CR":
                return <NoticePrivacyCostaRica />;
            case "es-GT":
                return <NoticePrivacyGuatemala />;
        }
    }

    componentDidMount() {
        $('html, body').scrollTop(0);
    }


    render() {
        const style = {
            bgSections: { background: 'url(./imgs/bglegal.jpg)' }
        }

        return (
            <section className="wrapper_legal ptop">
                <div className="bg_sections" style={style.bgSections}></div>
                <div className="container content_legal">
                    <h2 className="ttls fw600 c_white mb-3">Aviso de privacidad</h2>
                    {this.noticePrivacyByCountry()}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        countrySetting: state.countrySetting
    }
}

export default connect(mapStateToProps)(NoticePrivacy);