import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import PurchasedItemDetails from "../purchasedItemDetails";
import { billingService } from "../../services/billing.service";
import "./purchasedItem.scss";

const PurchasedItem = (props) => {
    const [item, setItem] = useState(props);
    const history = useHistory();
    const countrySetting = useSelector((state) => state.countrySetting);

    const [collapse, setCollapse] = useState(false);
    const [activeChevron, setStateChevron] = useState("");

    const Download = (OrderId, OrderNumber) => {
        fetch("/api/PurchaseOrder/DownloadSaleAsPDF", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ OrderId: OrderId }),
        })
            .then(function (response) {
                if (response.ok) {
                    return response.blob();
                }
            })
            .then(function (blob) {
                var FileSaver = require("file-saver");
                FileSaver.saveAs(blob, "order_" + OrderNumber + ".pdf");
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    function toggle() {
        setCollapse(!collapse);
        setStateChevron(activeChevron === "" ? "open" : "");
    }

    return (
        <div className="wrapper_purchased_item">
            <div className="head_purchased_item" onClick={toggle}>
                <div className="row text-center justify-content-around">
                    <div className="col first_data">
                        <span className={`ico_chevron ${activeChevron}`}>
                            <i className="fas fa-chevron-down"></i>
                        </span>
                        <span className="col_unic">
                            <h6 className="ttl_info">Fecha de pedido</h6>
                            <p className="p_info">{item.Date}</p>
                        </span>
                    </div>
                    <div className="col">
                        <h6 className="ttl_info">Número de pedido</h6>
                        <p className="p_info">{item.OrderNumber}</p>
                    </div>
                    <div className="col">
                        <h6 className="ttl_info">Total</h6>
                        <p className="p_info">
                            {countrySetting.currency}
                            {Number(item.Total).toLocaleString(countrySetting.cultureCode, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })}
                        </p>
                    </div>
                    <div className="col">
                        <h6 className="ttl_info">Estatus</h6>
                        <p className="p_info">
                            {" "}
                            <span className="paid">{item.Status}</span>{" "}
                        </p>
                    </div>
                </div>
            </div>
            <Collapse isOpen={collapse} className="body_purchased_item">
                <div className="row align-items-center">
                    <div className="col-md-8 info_item_shopping">
                        {item.Details.map((item) => {
                            return <PurchasedItemDetails {...item} />;
                        })}
                    </div>

                    <div className="col-md-4">
                        <div className="action_item_shopping">
                            {item.Status == "Inhabilita" && (
                                <p>
                                    <button
                                        className="btn btn_primary"
                                        onClick={() => Download(item.OrderId, item.OrderNumber)}
                                    >
                                        <i className="fas fa-download"></i> Descargar folios
                                    </button>
                                </p>
                            )}
                            {{
                                1: <p>{item.Invoice.ButtonText}</p>,
                                2: <>
                                    <p><button className="btn btn_secundary" onClick={() => window.open(item.Invoice.Url, "_blank")}><i className="fas fa-download"></i> {item.Invoice.ButtonText} </button></p>
                                </>,
                            }[item.Invoice.Status] || <p><button className="btn btn_primary" onClick={() => history.push(`create-invoice/${item.OrderNumber}`)}><i className="fas fa-plus"></i> Generar factura</button></p>
                            }
                            {/*{props.Status == "La orden ha sido pagada" && <p><button className="btn btn_primary" onClick={() => Download(props.OrderId, props.OrderNumber)} ><i className="fas fa-download"></i > Descargar folios</button></p>}*/}
                            {/*<p ><small className="fw300">Si tuviste algun problema para recibir tus folios, vuelve a intentarlo aquí.</small></p>*/}
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};
export default PurchasedItem;
