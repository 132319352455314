import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./registerServiceWorker";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import store from "./store/configureStore";

const rootElement = document.getElementById("root");

//Google Analytics()
const history = createBrowserHistory();
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID_GA);
ReactGA.pageview(history.location.pathname);
ReactGA.plugin.require("displayfeatures");

history.listen((location) => ReactGA.pageview(location.pathname));

ReactDOM.render(
  <Provider store={store}>
    <Router basename="/" history={history}>
      <App />
    </Router>
  </Provider>,
  rootElement
);

serviceWorker.unregister();
