import React from "react";
import { RedPay } from "../components/paymentMethod/RedPay/redpay";

export const PaymentMethod = (props) => {
  const switchPaymentMethod = () => {
    const { model } = props.location.state;
    let component;
    switch (model.PaymentMethodId) {
      // Red Pay
      case 8:
        component = <RedPay {...model}></RedPay>;
        break;
    }
    return component;
  };

  return (
    <section className="container_payment apm">{switchPaymentMethod()}</section>
  );
};
