import fetchIntercept from "fetch-intercept";
import { setBlocking } from "./hooks/blocking.hook";

export const registerInterceptor = fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here
    if (config) setBlocking(true);

    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    setBlocking(false);
    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object
    setBlocking(false);
    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    setBlocking(false);
    return Promise.reject(error);
  },
});
