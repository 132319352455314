import { callApi } from "./service";

export const paymentMethodService = {
  getCardList,
  deleteCard,
  createOrder,
};

async function getCardList() {
  return callApi("/verificationcard/getlistbyuserId", "Get")
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

async function deleteCard(data) {
  return callApi("/verificationcard/deletecard", "Post", data).then(
    (result) => {
      return result;
    }
  );
}

async function createOrder(model) {
  return callApi("/paymentmethod/createorder", "Post", model).then((result) => {
    return result;
  });
}
