import React, { Component } from "react"

export class Unsubscribe extends Component {

    render() {

        const style = {
            bg_picture: { background: 'url(./imgs/bgsections.png)' }
        }

        return (
            <section className="wrapper_sections hg_top">
                <div className="contain_sections">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="box_info_sections">
                                <h5>Unsubscribe</h5>
                                <hr />
                                {
                                    this.props.match.params.status == "Ok" ?
                                        <h6 className="fw300">¡Se ha dado de baja de las notificaciones exitosamente!</h6>
                                        : this.props.match.params.status == "Fail" ?
                                            <h6 className="fw300">¡No se ha encontrado el usuario especificado!</h6>
                                            :
                                            <h6 className="fw300">¡Ha currido un problema al procesar la petición!</h6>
                                }
                            </div>
                        </div>
                        {/*<div className="col-6">
                            <div
                                className="box_picture"
                                style={style.bg_picture}
                            ></div>
                        </div>*/}
                    </div>
                </div>
            </section>
        )
    }
}