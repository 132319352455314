import React, { useState } from "react";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
import classnames from "classnames";
import HubItemProvider from "../hubItemProvider";


const HubContainer = ({ providers }) => {
    const [activeTab, setActiveTab] = useState(12);

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <div className="wrapper_hub_container">
            <div className="mb-4 ttls_hub">
                <h4 className="ttls fw600">
                    <span className="ico_hub">
                        <img src="./imgs/ico_bag.png" alt="ICO" />
                    </span>
                    Diversión y entretenimiento sin límite
                </h4>
                <p className="font-italic fw300">
                    Encuentra tus marcas favoritas y sorpréndete
                </p>
            </div>
            <div className="box_nav_categories">
                <Nav tabs>
                    {
                        providers.map(item => 
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === item.ProviderCategoryId })}
                                onClick={() => {
                                    toggle(item.ProviderCategoryId);
                                }}
                            >{item.Description}
                            </NavLink>
                        </NavItem>
                        )
                    }
                </Nav>
            </div>

            <TabContent activeTab={activeTab}>
                {
                    providers.map(item => (<TabPane tabId={item.ProviderCategoryId}>
                        <HubItemProvider providers={item.ProviderDTO} />
                    </TabPane>))
                }
            </TabContent>
        </div>
    );
};

export default HubContainer;
