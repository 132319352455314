import React, { Component } from 'react';
import { Recharge } from '../components/recharge/recharge';
import $ from 'jquery'

export class RechargeView extends Component {
    static displayName = RechargeView.name;
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $('html, body').scrollTop(0);
    }

    render() {
        return (
            <section className="wrapper_recharge">
                <Recharge providerId={this.props.match.params.id} />
            </section>
        );
    }
}

