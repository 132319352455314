import React, { useEffect, useState } from "react";
import "./recharge.scss";

export function ProductItem({item, checked})
{
    const [showInfo, setShowInfo] = useState("");
    const fShowInfo = () => {
      setShowInfo(showInfo === "" ? "isShow" : "");
    };

  
    return(
        <>
            <input id={`radio_${item.ProductId}`} name="inputProduct" type="radio" checked={checked}/>
            <label for={`radio_${item.ProductId}`}>{item.ProductPrice}</label>
            <div id={`divBtnVer_${item.ProductId}`} className="btn_restric" onClick={fShowInfo}>
                <span>Ver más</span>
            </div>
            <div id={`infoTab${item.ProductId}`} className={`info_restric ${showInfo}`}>
                <span id={`divClose_${item.ProductId}`} className="close" onClick={fShowInfo}>
                <i className="fas fa-times-circle"></i>
                </span>
                    {item.Restriction}
            </div>
        </>
    );
}