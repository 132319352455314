import { callApi } from "./service";

export const productService = {
  getProducts,
};

async function getProducts(id) {
  return callApi(`/products/index${id}`, "Get")
    .then((result) => result)
    .catch(() => []);
}
