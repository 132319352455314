import React, { useEffect, useState } from "react";
import { RechargeForm } from "./rechargeForm";
import { RechargeDetails } from "./rechargeDetails";
import { useSelector } from 'react-redux';
import swal from "sweetalert";
import "./recharge.scss";

export function Recharge({ providerId }) {
    
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const [activeSection, setActiveSection] = useState("1");
  const [provider, setProvider] = useState({});
  const [products, setProducts] = useState([]);
  const [isVariable, setIsVariable] = useState(false);
  const [userInputValue, setUserInputValue] = useState([]);
  const [referenceData, setReferenceData] = useState({
    reference: "",
    confirm: "",
    name: "",
    amount: "",
    providerInputValueId: 0,
    isSaveReference: false,
  });
  const [selectedProduct, setSelectedProduct] = useState({
    productId: 0,
    providerId: providerId,
    name:"",
    cant: 1,
    productPrice: 0,
    publicPrice:0,
  });

  const onChangeReferenceData = (event) => {
    if (event.target.value == "true") event.target.value = true
    if (event.target.value == "false") event.target.value = false
    setReferenceData({
      ...referenceData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    getProductInfo();
  }, [providerId, isAuthenticated]);

  const getProductInfo = () => {
    window.setTimeout(
      function () {
        fetch("/api/Products/GetProductInfo?providerId=" + providerId, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((result) => {
            setProducts(result.Products);
            setProvider(result.Provider);
            if (result.Products.length > 0)
              setIsVariable(result.Products[0].IsVariable);
              setReferenceData({...referenceData, providerInputValueId: result.Provider.ProviderInputValueDto.ProviderInputValueId})
            fetch(
              "/api/Account/GetUserProfileInputValues?providerInputValueId=" +
                result.Provider.ProviderInputValueDto.ProviderInputValueId
            )
              .then((inputValueResponse) => inputValueResponse.json())
              .then((inputValueResult) => {
                setUserInputValue(inputValueResult);
              })
              .catch((error) => console.log("error", error));
          })
          .catch((error) => console.log("error", error));
      }.bind(this),
      2000
    );
  };

  const fToogleSection = (section) => {
    if (section == "2") {
      if (!referenceData.reference) {
        swal({
          title: "Validación",
          text: "Debes indicar una referencia",
          icon: "warning",
        });
        return
      }

      if (referenceData.reference !== referenceData.confirm) {
        swal({
          title: "Validación",
          text: "La referencia y la confirmación deben coincidir",
          icon: "warning",
        });
        return
      }
      if (referenceData.reference.length!=10 ) {
        swal({
          title: "Validación",
          text: "La referencia debe contener 10 dígitos",
          icon: "warning",
        });
        return
      }
      if ((referenceData.isSaveReference === true || referenceData.isSaveReference  == "true") && referenceData.name.length <= 0) {
        swal({
          title: "Validación",
          text: "Debes indicar un nombre para guardar la referencia",
          icon: "warning",
        });
        return
      }
      if (selectedProduct.productId == 0) {
        swal({
          title: "Validación",
          text: "Selecciona el monto de tu recarga",
          icon: "warning",
        });
        return
      }
    }

    if (activeSection !== section) setActiveSection(section);
  };

  const style = {
    bgProviders: {
      background:
        //"url('https://storagervadmindev.blob.core.windows.net/ctnadmincontentmx/Providers/RVO/2/HeaderRG.png') ",
        `url('${provider && provider.UrlHeader}')`,
    },
  };

  return (
    <div className="sec_recharge">
      <div className="bg_providers_recharge" style={style.bgProviders}></div>
      <div className="drawer_recharge ttls">
        <div className="cont_ttls">
          <h6 className="box_ttls fw300 mb-0">
            {isVariable ? (
              <span>Realiza tu pago</span>
            ) : (
              <span>Recarga ahora</span>
            )}{" "}
            de forma <span className="fw600">fácil y segura</span>
          </h6>
          <div className="box_brand">
            <img
              alt="Recargas movivendor en Red Voucher Online"
              src={provider && provider.UrlLogo}
            />
          </div>
        </div>

        {activeSection === "1" ? (
          <>
            <RechargeForm
              vSection={() => fToogleSection("2")}
              products={products}
              providerInputValues={provider && provider.ProviderInputValueDto}
              userInputValue={userInputValue}
              onChangeReferenceData={onChangeReferenceData}
              selectReferenceData={setReferenceData}
              isVariable={isVariable}
              setSelectedProduct={setSelectedProduct}
              referenceData={referenceData}
              selectedProduct={selectedProduct}

            />
          </>
        ) : (
          <>
            <RechargeDetails
              vSection={() => fToogleSection("1")}
              referenceData={referenceData}
              selectedProduct={selectedProduct}
            />
          </>
        )}
      </div>
    </div>
  );
}
