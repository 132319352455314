import React, { useEffect, useState } from "react";
import { NewReference } from "./newReference";
import { SelectReference } from "./selectReference";
import { ProductItem } from "./productItem";
import "./recharge.scss";

export function RechargeForm({vSection, products, providerInputValues, userInputValue, onChangeReferenceData, selectReferenceData, isVariable, setSelectedProduct, referenceData, selectedProduct}) {
  const [activeSec, setActiveSec] = useState("1");
  const [hasReferences, setHasReferences] = useState(false)
  const toggle = (sec) => {
    selectReferenceData(
      {
        ...referenceData, 
        reference : "",
        confirm: "",
        name: "",
        amount: "",
        isSaveReference: false,
      }
    )
    if (activeSec !== sec) setActiveSec(sec);
  };
const selectProductHandler = (product)=>
{ 
  setSelectedProduct({
    productId: product.ProductId,
    providerId: product.ProviderId,
    name: product.Name,
    cant: 1,
    productPrice: product.PublicPrice,
    // publicPrice: product.PublicPrice,
  })
}

  useEffect(() => {
    setHasReferences(userInputValue && userInputValue.length > 0)
  },[userInputValue]);

  return (
    <form className="cont_recharge_form">
      <h6 className="recharge_ttl fw300 box_flex align_center">
        <span className="box_flex justify_center align_center dots_num">1</span>
        { isVariable  ? 
            <span> Ingresa los datos de tu pago </span> 
          : <span>Elige tu recarga</span>
        }
      </h6>

      { activeSec === "1" && hasReferences  && ! isVariable ? (
        <SelectReference section={() => toggle("2")} userInputValue={ userInputValue } onSelectReference = {selectReferenceData} referenceValue = {referenceData.reference} />
      ) : (
        <NewReference section={() => toggle("1")} hasReferencesValue= {hasReferences}  onSelectReference = {onChangeReferenceData} isVariable={isVariable} providerInputValues = {providerInputValues}/>
      )}
      {
        isVariable ? "" : ""
      }
      <div className="box_montos">
        <h6 className="fw600">Selecciona un monto</h6>
        <div className="montos">
          {
            products.map((productItem) => 
            {
              return (
                <div className="inputGroup" onClick = {()=> selectProductHandler(productItem)}>
                  <ProductItem item={productItem} checked={productItem.ProductId === selectedProduct.productId}/>
              </div>
              )
            })
          }
        </div>
      </div>
      <hr />
      <div className="text-center">
        <button type="button" className="btn" onClick={vSection}>
          Siguiente
        </button>
      </div>
    </form>
  );
}
