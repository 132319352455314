import { callApi } from "./service";

export const countryService = {
    getAllCountry,
    getStateByCountry
};

async function getAllCountry() {
    return callApi("/country/GetAllCountry", "Get")
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log(error);
            return [];
        });
}

async function getStateByCountry(countryId) {
    return callApi("/country/GetStateByCountry?countryId="+countryId, "Get")
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log(error);
            return [];
        });
}