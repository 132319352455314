import { registerInterceptor } from "../fetchIntercept";

/**
 * Communication with the API
 * @param {*} url
 * @param {*} method
 * @param {*} body
 * @param {*} contentType
 */

export const callApi = async (url, method, body) => {
  var options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  return fetch(`/api${url}`, options)
    .then((response) => handleResponse(response, false))
    .catch(handleError);
};

const handleResponse = (response, isFileResponse) => {
  if (!isFileResponse)
    return response.text().then((text) => {
      let data;

      try {
        data = text && JSON.parse(text);
      } catch (e) {
        data = text;
      }

      if (!response.ok) {
        if (response.status === 401) window.location.href = "/";

        const error =
          data === ""
            ? { msg: response.statusText }
            : data ?? { msg: response.statusText };
        return Promise.reject(error);
      }

      return data;
    });
  else return response.blob();
};

const handleError = (error) => {
  console.log("error", error);
  if (error.message === "Failed to fetch")
    error.message = "El servicio no se encuentra disponible temporalmente";
  return Promise.reject(error);
};
