import React, { useState } from "react";
import ItemPayMethod from "./itemPayMethod";
import "./boxPayMethod.scss";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../store/actions";
import swal from "sweetalert";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { cartHook } from "../../hooks/cart.hook";
import { paymentMethodService } from "../../services/paymentMethod.service";

const BoxPayMethod = ({ onCloseClick, rechargeProducts }) => {
  const dispatch = useDispatch();
  const paymentMethods = useSelector((state) => state.user.paymentMethods);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const history = useHistory();
  const [onlyOneClick, setOnlyOneClick] = useState(false);

  const payOrder = (paymentMethodId) => {
    if (!onlyOneClick) {
      setOnlyOneClick(true);
      const model = {
        Products: cartHook.getItems(rechargeProducts),
        PaymentMethodId: paymentMethodId,
      };
      if (model.Products.length > 0) {
        paymentMethodService
          .createOrder(model)
          .then(function (result) {
            if (paymentMethodId != 1) {
              if (result.ValidationMessage) {
                swal({ text: result.ValidationMessage, icon: "error" });
                setOnlyOneClick(false);
              } else {
                if (result.Status) {
                  setOnlyOneClick(false);
                  if (!rechargeProducts) onCloseClick();
                  history.push({
                    pathname: "/checkout",
                    state: { model: result.Model },
                  });
                } else {
                  setOnlyOneClick(false);
                  swal({
                    text: "Ocurrió un error al abrir método de pago",
                    icon: "error",
                  });
                }
              }
            } else {
              localStorage["pruchaseTotal"] = 0;
              localStorage["pruchaseList"] = "[]";
              setOnlyOneClick(false);
              onCloseClick();
              $("body").append(result);
            }
          })
          .catch(function (error) {
            setOnlyOneClick(false);
            swal({
              text: "Ocurrió un error al abrir método de pago",
              icon: "error",
            });
          });
      } else {
        swal({
          text: "No hay ningún producto en el carrito de compras",
          icon: "error",
        });
        setOnlyOneClick(false);
      }
    }
  };

  return (
    <div className="box_paymethod">
      {isAuthenticated ? (
        paymentMethods.map((paymentMethod) => {
          return <ItemPayMethod data={paymentMethod} payOrder={payOrder} />;
        })
      ) : (
        <div className="text-center">
          <p>
            <small>¡Por favor inicia sesión para completar tu compra!</small>{" "}
          </p>
          <button
            className="btn btn_border ml-2"
            onClick={() => dispatch(setModal())}
          >
            Entrar
          </button>
        </div>
      )}
    </div>
  );
};

export default BoxPayMethod;
