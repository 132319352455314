import React from 'react';
import { useForm } from 'react-hook-form'
import swal from "sweetalert"

const ResetPassword = (props) => {
    const style = {
        bg_picture: { background: 'url(./imgs/bg_forgotpass.jpg)' }
    }

    const { register, handleSubmit } = useForm();
    const onSubmit = (data, e) => {
        fetch('/api/Account/ResetPassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            if (response.ok) {
                return response.json()
            }
        }).then(function (result) {
            if (result.Status) {
                e.target.reset();
                swal({ text: result.Message, icon: "success" });
            } else {
                swal({ text: result.Message, icon: "error" });
            }
        }).catch(function (error) {
            console.log(error);
            swal({ text: "Error", icon: "error" });
        });
    }

    return (
        <section className="wrapper_sections hg_top">
            <h1 className="ttls fw700 text-center mb-3 c_primary">Nueva contraseña</h1>
            <div className="contain_sections">
                <div className="row">
                    <div className="col-6 ">
                        <div className="box_info_sections">
                            <h6 className="fw300">Ingresa tu nueva contraseña</h6>
                            <hr />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input type="hidden" id="code" name="Code" value={props.location.search.split('code=')[1]} ref={register} />
                                <div className="form-group mb-4">
                                    <label for="email_user">Correo electrónico</label>
                                    <input type="email" className="form-control" id="Email" name="Email" aria-describedby="emailHelp" placeholder="example@mail.com" required ref={register} />
                                </div>
                                <div className="form-group mt-4 mb-4">
                                    <label for="new_password">Nueva contraseña</label>
                                    <input type="password" className="form-control" id="new_password" name="Password" placeholder="Nueva contraseña" required ref={register}></input>
                                </div>
                                <div className="form-group mt-4 mb-4">
                                    <label for="new_password">Confirmar nueva contraseña</label>
                                    <input type="password" className="form-control" id="new_password" name="ConfirmPassword" placeholder="Confirmar cueva contraseña" required ref={register}></input>
                                </div>

                <button type="submit" className="btn ">Guardar contraseña</button>
              </form>
            </div>

                    </div>
                    <div className="col-6">
                        <div
                            className="box_picture"
                            style={style.bg_picture}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ResetPassword