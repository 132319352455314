import React, { useState, useRef, useEffect, useCallback } from "react";
import "./recharge.scss";

export function NewReference({section, hasReferencesValue, onSelectReference, isVariable, providerInputValues}) {
  const [showInput, setShowInput] = useState("");
  console.log('inputValues',providerInputValues )
  const fShowInput = (e) => {
    
    e.target.value = e.target.checked
    onSelectReference(e)
    setShowInput(showInput === "" ? "isShow" : "");
  };


  return (
    <fieldset className="fieldset_ref_new">
      {hasReferencesValue ? 
            <span className="btn_close_ref_new" onClick={section}>
            <i className="fas fa-times"></i>
          </span> 
        : ""}
      {isVariable ? 
         ""
         :
         <legend>{providerInputValues && providerInputValues.Description}</legend>
      }
      
      <div className="form-row">
        <div className="form-group col-sm-6">
          <label for="new-ref" className="fw500">
            {providerInputValues && providerInputValues.InputName}
          </label>
          <input
            type={providerInputValues && providerInputValues.InputType}
            className="form-control"
            id="new-ref"
            name="reference"
            placeholder="Ingresa nuevo número"
            onChange={onSelectReference}
          />
        </div>
        <div className="form-group col-sm-6 mb-2">
          <label for="conf-ref" className="fw500">
            Confirmar {providerInputValues && providerInputValues.InputName}
          </label>
          <input
            type={providerInputValues && providerInputValues.InputType}
            className="form-control"
            id="conf-ref"
            name="confirm"
            placeholder="Confirmar"
            onChange={onSelectReference}
          />
        </div>
        { !isVariable ? 
        <>
            <div className="ml-2 custom-control custom-checkbox w-100 text-right">
              <input
                type="checkbox"
                className="cursor custom-control-input"
                id="isSaveReference"
                name="isSaveReference"
                onClick={fShowInput}
              />
              <label
                className="cursor custom-control-label fw600"
                for="isSaveReference"
                // onClick={fShowInput}
              >
                <small>Guardar número</small>
              </label>
            </div>
            <div className={`form_name_ref form-group col-sm-12 mt-2 ${showInput}`}>
              <label for="conf-ref" className="fw500">
                Nombre para identificar
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                id="conf-ref"
                placeholder="Nombre"
                onChange={onSelectReference}
              />
            </div>
        </> : 
        ""
        }
      </div>
    </fieldset>
  );
}
