import React from "react";

const NoticePrivacyMexico = () => {
  return (
    <div class="box_legal">
      <p>
       En cumplimiento a lo dispuesto en la Ley Federal de Protección de Datos
        Personales en Posesión de los Particulares (la “Ley”) y el Reglamento de
        la Ley (el “Reglamento”), ponemos a su disposición el presente Aviso de
        Privacidad (en lo sucesivo referido como el “Aviso”) en los siguientes
        términos:
      </p>
      <h6>
        I. IDENTIDAD Y DOMICILIO DEL RESPONSABLE DE LA PROTECCIÓN DE DATOS
        PERSONALES.
      </h6>
      <p>
        Red Voucher Systems, S.A. de C.V., (“Red Voucher” o el “Responsable”)
        con domicilio ubicado en la calle Texas 39, Colonia Nápoles, Delegación
        Benito Juárez, C.P.03810, Ciudad de México, será el responsable de
        recabar, almacenar y proteger los Datos Personales (según dicho término
        se define más adelante) de sus clientes y proveedores (individual e
        indistintamente, el “Titular”). Red Voucher ha designado un departamento
        a cargo del manejo, cumplimiento y protección de los Datos Personales
        (“Departamento de Datos Personales”), el cual dará trámite a tus
        solicitudes y fomentará la protección de los Datos Personales al
        interior de Red Voucher. Los datos de contacto y recepción de
        información y documentación relacionada:
      </p>
      <p>
        - Correo electrónico: dviveros@redcompanies.com.mx <br />
        - Dirección: El domicilio de Red Voucher. <br />- Atención: Departamento
        de Datos Personales.
      </p>
      <h6>II. CONSENTIMIENTO DEL TITULAR.</h6>

      <p>
        Para efectos de lo dispuesto en la Ley y demás legislación aplicable, el
        Titular manifiesta: (i) que el presente Aviso le ha sido dado a conocer
        por el Responsable; (ii) haber leído, entendido y acordado los términos
        expuestos en este Aviso, por lo que otorga su consentimiento respecto
        del tratamiento de sus Datos Personales. En caso de que los Datos
        Personales recopilados incluyan datos patrimoniales o financieros,
        mediante la firma del contrato correspondiente, sea en formato impreso o
        utilizando medios electrónicos y sus correspondientes procesos para la
        formación del consentimiento, se llevarán a cabo actos que constituyan
        el consentimiento expreso del titular; y (iii) que otorga su
        consentimiento para que Red Voucher o sus Encargados realicen
        transferencias y/o remisiones de Datos Personales en términos del
        apartado V del presente Aviso.
        <br />
        Será responsabilidad exclusiva del Titular la actualización de sus Datos
        Personales, así como su veracidad y exactitud. En todo momento se asume
        de buena fe que la información ha sido proporcionada por su legítimo
        propietario o poseedor de los derechos legales para ello, en ambos casos
        mayor de edad, por lo que Red Voucher no será responsable de la misma
        más que en los términos establecidos en el presente Aviso.
      </p>
      <h6>III. DATOS PERSONALES RECABADOS.</h6>
      <p>
        Red Voucher podrá recabar los siguientes datos personales (los “Datos
        Personales”): nombre completo, teléfono fijo o móvil y correo
        electrónico; tipo de tarjeta bancaria (crédito o débito; Visa o
        Mastercard), nombre del titular de la tarjeta, número de tarjeta, fecha
        de expiración y código de seguridad.
      </p>
      <h6>IV. FINALIDAD DE TRATAMIENTO DE LOS DATOS PERSONALES.</h6>
      <p>
        El Responsable recaba los Datos Personales para los siguientes fines:
      </p>
      <p>
        <b>Finalidades primarias.</b> Red Voucher tratará los Datos Personales
        del Titular con la finalidad de llevar a cabo las actividades y
        gestiones enfocadas al cumplimiento de las obligaciones originadas y
        derivadas de cualquier relación jurídica y comercial que establezca con
        motivo de la prestación de sus servicios o entrega de productos, tales
        como: identificación del cliente; contacto con el cliente; facturación;
        cobranza y atención al cliente.
      </p>
      <p>
        <b>Finalidades secundarias.</b> Asimismo, Red Voucher tratará Datos
        Personales para otras finalidades como enviar notificación de ofertas,
        avisos y/o mensajes promocionales; comunicaciones con fines de
        mercadotecnia o publicidad; realizar encuestas; sacar estadísticas;
        generar estudios de mercado; etc.
        <br />
        El Titular puede oponerse al tratamiento de sus datos para las
        finalidades secundarias a través de los medios puestos a su disposición
        para el ejercicio de sus Derechos ARCO (según dicho término se define
        más adelante). En caso de no oponerse en un plazo de cinco días hábiles
        posteriores a que sus datos fueron recabados, se entenderá que ha
        otorgado su consentimiento de forma tácita.
      </p>
      <h6>V. TRANSFERENCIAS Y/O REMISIONES DE DATOS.</h6>
      <p>
        En virtud de la firma del presente Aviso, usted autoriza expresamente a
        Red Voucher a transmitir vía electrónica y/o físicamente, copia de sus
        identificaciones oficiales y todos y cada uno de los documentos
        requeridos para identificarlo y brindarle los productos o servicios que
        haya requerido o para los que se haya registrado.
        <br />
        Red Voucher transmitirá los datos que usted le haya proporcionado o que
        haya recibido de forma legítima, independientemente de la fuente, cuando
        le sea requerido por orden de autoridad judicial o para cumplir con las
        obligaciones derivadas de relaciones jurídicas celebradas por Red
        Voucher en beneficio del Titular. Al momento de proporcionar sus Datos
        Personales, usted libera a Red Voucher de cualquier responsabilidad en
        la que pudiera incurrir por recabar o transmitir sus datos en
        cumplimiento de la prestación del servicio que recibe.
        <br />
        Los Datos Personales pueden ser transferidos y tratados dentro y fuera
        del país, por personas distintas a Red Voucher. En ese sentido, su
        información puede ser compartida con nuestros anunciantes,
        patrocinadores, publicistas, contratistas, socios comerciales y/o
        terceros con quién Red Voucher tenga celebrados o celebre diversos
        acuerdos comerciales tanto en territorio nacional como en el extranjero,
        para enviar, a discreción de Red Voucher, notificación de ofertas,
        avisos, publicidad y/o mensajes promocionales de acuerdo a sus intereses
        específicos, ya sean propios o de terceros, proporcionar datos
        estadísticos a anunciantes potenciales o llevar a cabo comunicaciones
        con fines de mercadotecnia, publicitarios o telemarketing; realizar
        investigaciones de mercadeo; realizar programas de beneficios e
        incentivos y en general para mejorar nuestros servicios. Sus Datos
        Personales también puede transferirse, almacenarse y procesarse en un
        país distinto de donde se proporcionó. Sí lo hacemos, transferimos la
        información de conformidad con las leyes de protección de la información
        aplicables y habiendo celebrado los contratos de transferencia y/o
        remisiones conducentes, en los que se establezcan las obligaciones de
        protección de los datos en términos del presente Aviso. Tomamos medidas
        para proteger la información personal sin importar el país donde se
        almacena o a donde se transfiere. Tenemos procedimientos y controles
        oportunos para procurar esta protección.
      </p>
      <h6>
        VI. MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE SUS DATOS PERSONALES.
      </h6>
      <p>
        Red Voucher resguardará los Datos Personales bajo estrictas medidas de
        seguridad administrativas, técnicas y físicas que han sido implementadas
        en términos del Reglamento, con el objeto de proteger y garantizar los
        Datos Personales contra daño, pérdida, alteración, destrucción o el uso,
        acceso o tratamiento no autorizados.
      </p>
      <p>
        Sin embargo, Red Voucher no garantiza que terceros no autorizados no
        puedan tener acceso a los sistemas físicos o lógicos de los Titulares o
        del Responsable o en los documentos electrónicos y archivos almacenados
        en sus sistemas. Por lo tanto, Red Voucher no será en responsable en
        ningún caso de los daños y perjuicios que se puedan derivar de dicho
        acceso no autorizado.
        <br />
        El Titular o su representante legal debidamente acreditado, podrán
        limitar el uso o divulgación de sus Datos Personales a través de los
        mismos medios y procedimientos dispuestos para el ejercicio de los
        Derechos ARCO. En caso de que dicha solicitud resulte procedente, el
        Titular será registrado en el listado de exclusión dispuesto por Red
        Voucher para dejar de recibir información relativa a campañas
        publicitarias o de mercadotecnia.
        <br />
        En suma, el Titular se encuentra asistido del derecho de inscribirse en
        el Registro Público para Evitar Publicidad (REPEP) de la PROFECO
        http://repep.profeco.gob.mx. Asimismo, si el Titular considera que Red
        Voucher ha vulnerado el derecho a la protección de datos personales,
        puede acudir al Instituto Nacional de Transparencia, Acceso a la
        Información y Protección de Datos Personales (“INAI”).
      </p>
      <p>
        Ï En todo momento, el Titular tiene derecho de acceder, rectificar y
        cancelar sus Datos Personales (“Derechos ARCO”); oponerse al tratamiento
        de los mismos o revocar el consentimiento que haya proporcionado. Para
        esto, deberá presentar una solicitud en formato libre y que deberá
        contener la información y documentación siguiente:
      </p>
      <ul>
        <li>
          a. Nombre del Titular, domicilio, correo electrónico u otro medio para
          comunicarle la respuesta a su solicitud;
        </li>
        <li>
          b. Los documentos vigentes que acrediten su identidad (copia simple en
          formato impreso o electrónico de su credencial de elector, pasaporte o
          visa) o, en su caso, la representación legal del Titular (copia simple
          en formato impreso o electrónico de la carta poder simple con firma
          autógrafa del Titular, el mandatario y sus correspondientes
          identificaciones oficiales vigentes – credencial de elector, pasaporte
          o Visa);
        </li>
        <li>
          c. La descripción clara y precisa de los datos respecto de los que
          busca ejercer alguno de los Derechos ARCO, y
        </li>
        <li>
          d. Cualquier otro elemento o documento que facilite la localización de
          los datos personales del Titular.
        </li>
      </ul>

      <p>
        En el caso de las solicitudes de rectificación de Datos Personales, el
        Titular deberá también indicar las modificaciones a realizarse y aportar
        la documentación que sustente su petición. Para dar cumplimiento a la
        obligación de acceso a sus datos personales, se hará previa acreditación
        de la identidad del titular o personalidad del representante; poniendo
        la información a disposición en sitio en el domicilio del Responsable.
        Si la información solicitada así lo permite, se podrá acordar otro medio
        entre el Titular y el Responsable.
        <br />
        Para la petición del formato, recepción y respuesta de las solicitudes
        para ejercer los Derechos ARCO, la revocación de consentimiento y los
        demás derechos previstos en la Ley, se puede poner en contacto con el
        Departamento de Datos Personales en el siguiente correo electrónico:
        dviveros@redcompanies.com.mx. De igual forma podrán llamar al teléfono:
        +52 (55)55959476, de lunes a viernes en un horario de 8 a.m. a 7 p.m. o
        bien, acudir a las oficinas ubicadas en el domicilio mencionado en el
        punto I de este Aviso.
        <br />
        En caso de que la información proporcionada en la solicitud sea errónea
        o insuficiente o no se acompañen los documentos de acreditación
        correspondientes, Red Voucher podrá solicitar, dentro de los cinco días
        hábiles siguientes a la recepción de la solicitud, que el Titular aporte
        los elementos o documentos necesarios para dar trámite a la misma. El
        Titular contará con diez días hábiles para atender el requerimiento,
        contados a partir del día siguiente en que lo haya recibido. De no dar
        respuesta en dicho plazo, se tendrá por no presentada la solicitud
        correspondiente.
        <br />
        Red Voucher responderá al Titular en un plazo máximo de veinte días
        hábiles, contados desde la fecha en que se recibió la solicitud a efecto
        de que, si resulta procedente, haga efectiva la misma dentro de los
        quince días hábiles siguientes a que se comunique la respuesta. En todos
        los casos, la respuesta se dará por la misma vía por la que se haya
        presentado la solicitud o en su caso, por cualquier otro medio acordado
        con el Titular. Los plazos antes referidos podrán ser ampliados en
        términos de la Ley.
      </p>
      <h6>
        VIII. RECOLECCIÓN DE DATOS AL NAVEGAR EN SITIOS Y PÁGINAS WEB DE RED
        VOUCHER.
      </h6>
      <p>
        Red Voucher puede recabar datos a través de sus sitios Web, o mediante
        el uso de herramientas de captura automática de datos. Dichas
        herramientas le permiten recolectar la información que envía su
        navegador a dichos sitios Web, tales como el tipo de navegador que
        utiliza, el idioma de usuario, los tiempos de acceso y la dirección IP
        de sitios Web que utilizó para acceder a los sitios del Responsable o
        sus Encargados. Estos datos se recaban con la finalidad identificar al
        cliente y brindarle un mejor servicio durante el tiempo que usa la
        página web.
        <br />
        Dentro de las herramientas de captura automática de datos utilizadas por
        Red Voucher en sus sitios y páginas web se encuentran las cookies, los
        Web beacons, y los enlaces en los correos electrónicos.
        <br />
        Uso de Cookies. El correcto funcionamiento de los sitios de Red Voucher
        requieren de la habilitación de “cookies” en su navegador de Internet.
        Las "cookies" son pequeños archivos de datos transferidos por el sitio
        Web al disco duro de su computadora cuando navega por el sitio. En la
        mayoría de los navegadores las cookies se aceptan automáticamente en
        virtud de su configuración predeterminada, usted puede ajustar las
        preferencias de su navegador para aceptar o rechazar las cookies. La
        desactivación de las cookies puede inhabilitar diversas funciones de los
        sitios web de Red Voucher o que no se muestren correctamente. En caso de
        que usted prefiera eliminar las cookies, usted puede eliminar el archivo
        al final de cada sesión del explorador.
        <br />
        Uso de Web beacons. También conocidos como etiquetas de Internet,
        etiquetas de píxel y clear GIFs. Red Voucher puede utilizar en sus
        sitios web y en sus correos electrónicos con formato HTML los Web
        beacons, solos o en combinación con las cookies, para recopilar
        información sobre el uso de los sitios web y su interacción con el
        correo electrónico. El Web beacon es una imagen electrónica, llamada de
        un solo píxel (1x1) o GIF que puede reconocer información que es
        procesada en su computadora, como el caso de las cookies, la hora y
        fecha en que el sitio y sus secciones son visualizados.
        <br />
        Vínculos en los correos electrónicos de Red Voucher. Los correos
        electrónicos pueden incluir vínculos que permiten a Red Voucher saber si
        usted activó dicho vínculo y visitó la página web de destino, pudiendo
        esta información ser incluida en su perfil. Asimismo, pueden incluir
        vínculos diseñados para dirigirlo a las secciones relevantes de los
        sitios Web, al redireccionarlo a través de los servidores de Red Voucher
        el sistema de redireccionamiento permite determinar la eficacia de las
        campañas de marketing en línea.
      </p>
      <h6>
        IX. DERECHO DE REVOCAR SU CONSENTIMIENTO PARA EL TRATAMIENTO DE DATOS.
      </h6>
      <p>
        En todo tiempo usted puede revocar el consentimiento que nos ha
        entregado para el tratamiento de sus Datos Personales con el propósito
        que se deje de hacer uso de los mismos. Para ello es necesario que
        presente su petición según se indicó en la sección de “Limitación del
        uso o divulgación de datos personales”, del presente Aviso.
      </p>
      <p>La solicitud deberá indicar:</p>
      <ul>
        <li>
          a. Nombre del Titular y domicilio, correo electrónico u otro medio
          para comunicarle la respuesta a su solicitud;
        </li>
        <li>
          b. Los documentos vigentes que acrediten su identidad (copia simple en
          formato impreso o electrónico de su credencial de elector, pasaporte o
          visa) o, en su caso, la representación legal del Titular (copia simple
          en formato impreso o electrónico de la carta poder simple con firma
          autógrafa del Titular, el mandatario y sus correspondientes
          identificaciones oficiales vigentes – credencial de elector, pasaporte
          o Visa);
        </li>
        <li>
          c. La descripción clara y precisa de los Datos Personales respecto de
          los cuales se busca revocar el consentimiento y cualquier documento
          que facilite la localización de los datos personales.
        </li>
      </ul>
      <p>
        En un término de veinte días le daremos contestación sobre la
        procedencia de la misma, por el medio que usted haya indicado en la
        propia solicitud. El plazo se contará desde la fecha en que Red Voucher
        tenga conocimiento de su solicitud hasta la fecha en que se entregue el
        documento al servicio postal, sea enviado el correo electrónico
        correspondiente o al medio de contacto que usted haya proporcionado.
        <br />
        De igual manera, se hace de su conocimiento que una vez transcurrido el
        plazo de un año contado desde la fecha en que Red Voucher no requiera su
        información para el cumplimiento de sus obligaciones, respecto de la
        relación que lo une con usted, Red Voucher se reserva el derecho de
        cancelar de su base de datos la información que contenga sus datos
        personales y podrá borrarla de su base de datos y, en su caso, destruir
        cualquier medio físico u electrónico que pudiera contenerla.
      </p>
      <h6>X. CAMBIOS AL AVISO.</h6>
      <p>
        Red Voucher se reserva el derecho a modificar el presente Aviso de
        Privacidad en el futuro. En todo caso, cualquier modificación al mismo
        se hará de su conocimiento a través de su correo electrónico y/o
        mediante la publicación del mismo en la página web
        http://www.red-voucher.com.
      </p>
      <p>
        Red Voucher no será responsable en el caso de que el Titular no reciba
        la referida notificación de cambio en el Aviso de Privacidad si
        existiere algún problema con su cuenta de correo electrónico o de
        transmisión de datos por internet. Sin embargo, por su seguridad, el
        Aviso de Privacidad vigente estará disponible en todo momento en la
        página web señalada en el punto anterior.
        <br />
        El Responsable entenderá que en caso de que el Titular no exprese lo
        contrario, significa que ha leído, entendido y acordado los términos ahí
        expuestos; es decir, entiende que ha manifestado su consentimiento a los
        cambios y/o actualizaciones respecto al tratamiento de sus Datos
        Personales.
      </p>
      <h6>Última actualización del Aviso de Privacidad: 12 de mayo de 2020.</h6>
    </div>
  );
};

export default NoticePrivacyMexico;
