import React, { useState } from 'react'
import VideoProvider from '../videoProvider'

import './asideProvider.scss'

const AsideProvider = (props) => {
    const [isAll, setIsAll] = useState(props.isAll);
    const toggleCategories = (isAll) => { setIsAll(isAll); props.toggleCategories(isAll); }

    return (
        <div className="aside_providers">

            <div className="wrapper_media_provider mb-4">

                <VideoProvider {...props} />
                {/* <ImgProvider /> */}

            </div>

            <div className="wrapper_categories_provider">
                <ul className="list-group wrapper_scroll">
                    <li className={`item_category ${!isAll ? 'active' : ''}`} onClick={() => toggleCategories(false)}>Lo más vendido</li>
                    <li className={`item_category ${isAll ? 'active' : ''}`} onClick={() => toggleCategories(true)}>Todos los productos</li>
                </ul>
            </div>

        </div>
    )
}

export default AsideProvider;