import React, { useState, useCallback } from "react";
import "./recharge.scss";

export function SelectReference({section, userInputValue, onSelectReference, referenceValue}) {
  const handleSelectItem = useCallback(event => {
    onSelectReference(
        {
          reference : event.target.value,
          confirm: event.target.value,
          name: "",
          amount: "",
          providerInputValueId: 0,
          isSaveReference: false,
        }
      )
  }, [onSelectReference])

  return (
        <div className="box_select_ref">
          <div className="box_btn">
            <span className="btn-form fw500" onClick={section}>
              Nuevo número
            </span>
          </div>
          <div className="form-group">
            <label for="ref" className="fw500">
              Número telefónico{" "}
            </label>
            <select className="form-control" name="ref" id="ref" required onChange={handleSelectItem} value={referenceValue} >
            <option value="">Seleccione una de sus referencias</option>
              {userInputValue && userInputValue.map((inputItem) => {
                return(
                  <option key={`opt_${inputItem.userProfileInputValueId}`} value={inputItem.Value}  >
                  {`${inputItem.KeyName} - ${inputItem.Value}`}
                </option>
                );
              })}
            </select>
          </div>
        </div>
    
  );
}
