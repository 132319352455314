import React from 'react';

const ItemPayMethod = ({ data, payOrder }) => {
    return (
        <button
            type="button"
            className="item_paymethod trans"
            onClick={()=> payOrder(data.paymentMethodId)}>
            <img src={data.UrlLogo} alt="Métodos de pagos en Red Voucher Online, Red Pay Pasarela de pago, México" />
        </button>

    )
}

export default ItemPayMethod