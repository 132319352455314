import React, { Component } from "react";
import { connect } from "react-redux";
import { setModal } from "../../store/actions";
import "./hubItemProvider.scss";

class HubItemProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providersOptions: props.providers,
    };
  }

  componentWillReceiveProps(next_props) {
    this.setState({ providersOptions: next_props.providers });
  }

  validateSetModal = (to) => {
    if (!this.props.isAuthenticated) this.props.setModal();
    else window.location.href = to;
  };

  render() {
    const { providersOptions } = this.state;
    return (
      <div className="box_container_hub">
        {providersOptions.map((providersOption) => {
          return (
            <>
              {providersOption.ProviderInputValueDto.ProviderInputValueId ==
              0 ? (
                <a
                  className="item_provaider_hub"
                  key={providersOption.Id}
                  onClick={() =>
                    this.validateSetModal("/provider?id=" + providersOption.Id)
                  }
                >
                  <div className="item__hub">
                    <img
                      className="img_provider"
                      src={providersOption.ProviderLogo}
                      alt="Descuentos y promociones especiales en Red Voucher Online"
                    />
                  </div>
                </a>
              ) : (
                <a
                  className="item_provaider_hub"
                  key={providersOption.Id}
                  onClick={() =>
                    this.validateSetModal("/recharge/" + providersOption.Id)
                  }
                >
                  <div className="item__hub">
                    <img
                      className="img_provider"
                      src={providersOption.ProviderLogo}
                      alt="Descuentos y promociones especiales en Red Voucher Online"
                    />
                  </div>
                </a>
              )}
            </>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { isAuthenticated: state.user.isAuthenticated };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModal: () => {
      dispatch(setModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubItemProvider);
