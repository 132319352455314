import { callApi } from './service';

export const accountService = {
    getCompanyCatalog,
    getRegisterTypeCatalog,
    register
};

async function getCompanyCatalog(company) {
    return callApi(`/account/companycatalog?company=${company}`, "Get")
        .then((result) => result)
        .catch(() => []);
}

async function getRegisterTypeCatalog(id) {
    return callApi(`/account/registertypecatalog?company=${id}`, "Get")
        .then((result) => result)
        .catch(() => []);
}

async function register(model) {
    return callApi("/account/register", "Post", model).then((result) => result);
}