import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './modalMessage.scss';

/**
 * Show modal
 * @param {*} title: Modal title
 * @param {*} message: Modal message
 * @param {*} show: Indicates if the modal should be displayed
 * @param {*} colorClass: Icon color
 * @param {*} iconClass: Type of icon to display (success)
 * @param {*} handleShow(bool): Handling the opening / closing of the modal
 */

export function ModalMessage(props) {

    const setClose = () => props.handleShow(false)
    const { title, show, message, colorClass, iconClass } = props;

    return (
        <Modal
            size="sm"
            show={show}
            onHide={setClose}
        >
            <Modal.Body closeButton>
                <div className="box_modal_ico">
                    <span className={colorClass}>
                        <i className={iconClass}></i>
                    </span>
                </div>
                <h2 className="ttls fw700 text-center">{title}</h2>
                <h4 className="text-center mt-2">{message}</h4>
            </Modal.Body>
        </Modal>
    );
}