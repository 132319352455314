import React, { useState, useEffect } from "react";
import "./cardItem.scss";
import swal from "sweetalert";

import { cardService } from "../../../services/card.service";

export const CardItem = ({ selectCard }) => {
    const [cardList, setCardList] = useState([]);
    const [card, setCard] = useState(undefined);

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        cardService.getCardList().then((result) => {
            setCardList(result);
        });
    };

    const newCard = () => {
        selectCard({ CardId: "new" });
    };

    const onSelectCard = (item) => {
        setCard(item);
        selectCard(item);
    };

    const deleteCard = (item) => {
        swal({
            title: "¿Estás seguro que deseas eliminar esta tarjeta?",
            text: item.CardNumber,
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancelar", "Si, eliminar"],
        }).then((willDelete) => {
            if (willDelete) {
                cardService.deleteCard(item.CardId).then(function (response) {
                    if (response.IsSuccessful) {
                        getList();
                        swal({ text: response.Message, icon: "success" });
                    } else {
                        swal({ text: response.Message, icon: "error" });
                    }
                });
            }
        });
    };

    return (
        <section className="wrapper_item_card_register wrapper_other container-fluid">
            <h1>Mis tarjetas</h1>
            <div className="row mb-3 mt-1">
                {cardList.map((item) => (
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3" key={item.CardId}>
                        <div
                            className={`item_card_register ${card && card.CardId == item.CardId && "is_select-xxxxxxxxx"
                                }`}
                        >
                            <span className="ico_delete" onClick={() => deleteCard(item)}>
                                <i className="fas fa-minus-circle"></i>
                            </span>
                            <span className="spanico">
                                <i className="fas fa-dollar-sign"></i>
                            </span>
                            <div className="content_item" onClick={() => onSelectCard(item)}>
                                <p className="imgico">
                                    {
                                        {
                                            "001": (
                                                <img
                                                    src="./imgs/paymentMethod/visa.jpg"
                                                    alt="tarjeta visa"
                                                />
                                            ),
                                            "002": (
                                                <img
                                                    src="./imgs/paymentMethod/masterc.jpg"
                                                    alt="tarjeta master card"
                                                />
                                            ),
                                            "003": (
                                                <img
                                                    src= "./imgs/paymentMethod/amex.png"
                                                    alt = "tarjeta amex"
                                                />
                                            ),
                                        }[item.CardType]
                                    }
                                </p>

                                <h1 className="mb-1 fw400 text-right">{item.CardNumber}</h1>
                                <p className="mb-0 fw400 pvig text-right">
                                    {item.FirstName} {item.LastName}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="col-lg-3 col-md-4 col-sm-6 mb-3" onClick={newCard}>
                    <div className="item_card_register card_more">
                        <div className="content_item text-center">
                            <span className="icomore">
                                <i className="fas fa-plus"></i>
                            </span>
                            <p className="mb-0 pvig fw400">Tarjeta nueva</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
