import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { billingService } from "../services/billing.service";

export const CreateInvoice = (props) => {
    const history = useHistory();
    const [invoiceInfoConfigs, setInvoiceInfoConfigs] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    const getInformation = async () => {
        const information = await billingService.getInformation();
        setInvoiceInfoConfigs(information);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData);
        const result = await billingService.create(formProps);
        if (result.UrlRedirect)
            history.push(result.UrlRedirect)
        else
            swal({ text: result.Message, icon: "error" });
    }


    const renderControls = () => {
        let elements = new Array();
        invoiceInfoConfigs.forEach(control => {
            let id = control.Id && control.Id != "" ? control.Id : undefined;
            let label = <label for={id}>{control.Label}</label>;
            let value = control.PreviousValue && control.PreviousValue != "" ? control.PreviousValue : undefined;

            let controlType;
            switch (control.ControlType.toLowerCase()) {
                case "select":
                    let options = control.InvoiceSelectValues.map(o => <option value={o.Value}>{o.Key}</option>);
                    controlType = <select id={id} name={id} defaultValue={value} className="form-control" required>{options}</select>;
                    break;
                case "input":
                    controlType = <input type="text" id={id} name={id} defaultValue={value} className="form-control" required />;
                    break;
            }

            elements.push(<div className="form-group mb-4">{label}{controlType}</div>);
        });

        return elements
    }

    const style = { bg_picture: { background: 'url(./imgs/bglegal.jpg)' } };

    return (
        <section className="wrapper_sections hg_top">
            <h1 className="ttls fw700 text-center mb-3 c_primary">Datos de factura</h1>
            <div className="contain_sections">
                <div className="row">
                    <div className="col-6 ">
                        <div className="box_info_sections">
                            <form id="formInvoice" method="post" onSubmit={handleSubmit}>
                                <input type="hidden" id="HdfID" name="HdfID" value={props.match.params.id} />
                                {renderControls()}
                                <button type="submit" className="btn">Guardar</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-6">
                        <div
                            className="box_picture"
                            style={style.bg_picture}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    )
}