export const getUser = () => {
  return (dispatch) => {
    fetch("/api/Account/IsAuthenticated", { method: "GET" })
      .then((response) => response.json())
      .then((result) => {
        dispatch(
          setUser({
            isAuthenticated: result.IsAuthenticated,
            userName: result.User,
            paymentMethods: result.PaymentMethods,
            UserMenu: result.UserMenu,
          })
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
};

const setUser = (data) => {
  return {
    type: "Get_User",
    user: { ...data },
  };
};

export const setModal = () => {
  return {
    type: "Set_Modal",
  };
};

export const setCompany = (company) => {
  return {
    type: "Set_Company",
    company: company,
  };
};

export const setCompanyLogo = (companyLogo) => {
  return {
    type: "Set_CompanyLogo",
    companyLogo: companyLogo,
  };
};

export const setCountrySetting = (countrySetting) => {
  return {
    type: "Set_CountrySetting",
    countrySetting: countrySetting,
  };
};

export const toggleBlocking = (payload) => {
  return {
    type: "Toggle_Blocking",
    payload,
  };
};
