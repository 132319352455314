import { callApi } from './service'

export const couponService = {
	getAll,
	download
}

async function getAll() {
	return callApi('/coupon/couponcarrousel', 'GET')
		.then((result) => {
			return result;
		});
}

async function download() {
	return callApi('/coupon/download', 'GET')
		.then((result) => {
			return result;
		});
}