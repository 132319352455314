import React, { useEffect, useState } from 'react';

import PurchasedItem from '../purchasedItem';
import { PaginatedPage } from './pagination';
import './shoppingHistoryWrapper.scss'

const ShoppingHistoryWrapper = ({ shoppingHistory }) => {
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const totalPages = shoppingHistory.length > 0 ? shoppingHistory.length : 1;

    const style = {
        bgProviders: { background: 'url(./imgs/bgsections.png)' }
    }

    useEffect(() => {
        if (shoppingHistory.length > 0)
            setItems(shoppingHistory[0]);
    }, [shoppingHistory]);

    const setPageItems = (page) => {
        setItems([]);
        setTimeout(() => {
            setPage(page);
            setItems([...shoppingHistory[page - 1]]);
        }, "500");

    }

    return (
        <div className="wrapper_shoppingHistory ">
            <div className="bg_sections" style={style.bgProviders}></div>

            <div className="contain_shop_history">
                <h2 className="ttls_sections_shop">Mis compras</h2>
                <div className="boxes_hop_history">
                    {
                        items.map(item => <PurchasedItem {...item} />)
                    }
                    <div className="paginated_page">
                        <PaginatedPage
                            totalPages={totalPages}
                            currentPage={page}
                            onChange={page => setPageItems(page)}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ShoppingHistoryWrapper;