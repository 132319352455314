import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import Moment from "react-moment";
import "../components/shoppingHistoryWrapper/shoppingHistoryWrapper.scss";
import "../components/purchasedItem/purchasedItem.scss";
import { cartHook } from "../hooks/cart.hook";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";

class PurchaseOrderDetail extends Component {
  static displayName = PurchaseOrderDetail.name;
  constructor(props) {
    super(props);
    this.state = {
      purchaseOrderDetail: "",
    };
  }

  ps = (OrderId) => {
    window.setTimeout(
      function () {
        fetch("/api/PurchaseOrder/Detail?id=" + OrderId + "&isSale=true", {
          method: "GET",
        })
          .then((response) => response.json())
          .then((result) => {
            this.setState({ purchaseOrderDetail: result });
            if (this.state.purchaseOrderDetail.IsBillable && result.IsInvoiceGenerated == 0) {
              window.setTimeout(() => {
                swal({ text: "En 5 segundos sera redirigido a facturación", icon: "info" });
                window.setTimeout(function () {
                  document.getElementById("btnToInvoice").click();
                }, 5000);
              }, 5000);
            }
          })
          .catch((error) => console.log("error", error));
      }.bind(this),
      2000
    );
  };

  componentWillMount() {
    this.ps(this.props.match.params.id);
    cartHook.clean();
  }

  componentDidMount() {
    $("html, body").scrollTop(0);
  }

  getProductOrder = () => {
    return this.state.purchaseOrderDetail.purchaseOrderDetail.map((item) => (
      <tr key={item.ProductId}>
        <td>{item.Quantity}</td>
        <td>
          {item.ProductName} - {item.ProductCode}
        </td>
        <td>
          {this.props.countrySetting.currency}
          {item.ProductPrice.toLocaleString(
            this.props.countrySetting.cultureCode,
            { minimumFractionDigits: 0, maximumFractionDigits: 2 }
          )}
        </td>
        <td id="D548195">
          {this.props.countrySetting.currency}
          {item.SubTotal.toLocaleString(this.props.countrySetting.cultureCode, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </td>
      </tr>
    ));
  };

  showGenerateInvoice = () => {
    return this.state.purchaseOrderDetail.IsBillable && this.state.purchaseOrderDetail.IsInvoiceGenerated == 0 ? (
      <div className="text-center mt-2">
        <a id="btnToInvoice"
          href={"/create-invoice/" + this.state.purchaseOrderDetail.OrderNumber}
          className="btn btn-link"
        >
          Generar Factura
        </a>
      </div>
    ) : (
      ""
    );
  };

  render() {
    console.log("this.state: ", this.state);
    const style = {
      bgProviders: { background: "url(./imgs/bgsections-details.jpg)" },
    };

    return (
      <div className="wrapper_shop_details">
        <div className="bg_sections" style={style.bgProviders}></div>

        {this.state.purchaseOrderDetail !== "" ? (
          <div className="contain_shop_history">
            <div className="box-ttls-shop">
              <img
                src="./imgs/ico-detailsshop.png"
                alt="Compra realizada en Red Voucher Online"
              />
              <h4 className="ttl_shop">¡Compra realizada!</h4>
              <h5 className="fw700">
                Orden: {this.state.purchaseOrderDetail.OrderNumber}
              </h5>
              <p className="ptext">
                {this.state.purchaseOrderDetail.IsSaleText}
              </p>
            </div>
            <hr />

            <div className="box_status_shop">
              <div className="info_status">
                <span>Fecha: </span>
                <span className="fw700">
                  <Moment format="DD/MM/YYYY">
                    {this.state.purchaseOrderDetail.Date}
                  </Moment>
                </span>
              </div>
              <div className="info_status ">
                <span>Status:</span>
                <span className="fw700">
                  {this.state.purchaseOrderDetail.Status}
                </span>
              </div>
            </div>

            <div class="box_table_details table-responsive">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th className="ttl_info" scope="col">
                      Cant.
                    </th>
                    <th className="ttl_info" scope="col">
                      Descripción
                    </th>
                    <th className="ttl_info" scope="col">
                      Precio
                    </th>
                    <th className="ttl_info" scope="col">
                      Subtotal
                    </th>
                  </tr>
                </thead>
                <tbody>{this.getProductOrder()}</tbody>
              </table>
            </div>

            <div className="text-right">
              <h6 className="ttl_info">
                Total {this.props.countrySetting.currency}
                {Number(this.state.purchaseOrderDetail.Total).toLocaleString(
                  this.props.countrySetting.cultureCode,
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }
                )}
              </h6>
            </div>
            <div className="text-center">
              <br />
              <a href="/home" className="btn btn-light mb-1">
                Inicio
              </a>
              &emsp;
              <a href="/shopping-history" className="btn mb-1">
                Historial de Compra
              </a>
              &emsp;
              {this.showGenerateInvoice()}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countrySetting: state.countrySetting,
  };
};

export default connect(mapStateToProps)(PurchaseOrderDetail);
