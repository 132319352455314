import React, { Component } from "react";
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from '../../store/actions';
import { ItemProviderIndividual } from '../wrapperProviderIndividual/itemProviderIndividual'
import './wrapperProviderIndividual.scss'

const WrapperProviderIndividual = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const countrySetting = useSelector(state => state.countrySetting);

    const validateSetModal = () => {
        if (!isAuthenticated) {
            dispatch(setModal());
        }
    }

    return (
        <div className="wrapper_hub_container">
            <div className="mb-4 ttls_hub">
                <h4 className="ttls fw600">
                    <span className="ico_hub">
                        <img src="./imgs/ico_bell.png" alt="ICO" />
                    </span>
                    Lo más popular</h4>
                <p className="font-italic fw300">Mira lo que prefiere la comunidad de Red Voucher Online.</p>
            </div>


            <div className="wrapper_provider_individual">
                <div className="row">
                    {/*<ItemProviderIndividual />
                       <ItemProviderIndividual />*/}

                    {/*<div className="col-sm-6 mb-5">*/}
                    {/*    <a className="item_provider_individual" target="_blank" href="https://www.facebook.com/redvoucher">*/}
                    {/*        <img src="./imgs/promofb.png" alt="Item Proveedor" />*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                    {countrySetting.isoCurrency == "MXN" && <div className="col-sm-6 mb-5">
                        <Link
                            to={isAuthenticated ? "/provider?id=13" : ""}
                            className="item_provider_individual"
                            onClick={validateSetModal}
                        >
                            <img src="./imgs/promoxbox.png" alt="Item Proveedor" />
                        </Link>
                    </div>}
                    <div className="col-sm-6">
                        <Link
                            to={isAuthenticated ? "/provider?id=21" : ""}
                            className="item_provider_individual"
                            onClick={validateSetModal}
                        >
                            <img src="./imgs/promoamazon.png" alt="Item Proveedor" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default WrapperProviderIndividual;